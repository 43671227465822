
#container{
    .borad_tab{
        position: relative;
        display: block;
        width: 100%;
        overflow-x: auto;
        ul{
            display: table;
            &::before{
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #959595;
            }
            li{
                display: table-cell;
                min-width: 180px;
                a{
                    position: relative;
                    display: block;
                    height: 48px;
                    line-height: 48px;
                    padding: 0 36px 0 20px;
                    margin-left: -1px;
                    background: #fafafa;
                    border: 1px solid #d1d1d1;
                    border-bottom: 1px solid #959595;
                    border-radius: 6px 6px 0 0;
                    font-size: 16px;
                    color: #666;
                    vertical-align: middle;
                    text-align: center;
                    &.on{
                        border-color: #959595;
                        color: #333;
                        background: #fff;
                        border-bottom: 0 none;
                        z-index: 1;
                    }
                    .ico_close{
                        display: inline-block;
                        width: 11px;
                        height: 11px;
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        margin-top: -6px;
                        background: url("/static/img_admin/board_tab_close.png");
                        font-size: 0;
                    }
                }
            }
        }
    }

    .table_modify_list{
        &::after{
            content: '';
            display: block;
            clear: both;
        }
        border-top: 1px solid #959595;
        border-bottom: 1px solid #ebebeb;
        padding: 0 40px;
        dl{
            float: left;
            padding-right: 10px;
            dt{
                width: 160px;
                padding-top: 18px;
                min-height: 58px;
                line-height: 40px;
                height: 58px;
            }
            dd{
                padding-bottom: 30px;
                min-height: 58px;
                line-height: 40px;
                .form_control{
                    height: 36px;
                    &.date_picker{
                        width: 165px;
                        padding-right: 47px;
                        background-image: url(/static/img_admin/ico_calendar2.png);
                        background-repeat: no-repeat;
                        background-position-y: 50%;
                        background-position-x: calc(100% - 20px);
                    }
                }
                >div{
                    display: inline-block;
                    margin-right: 10px;
                }
                .btn{
                    height: 36px;
                    line-height: 36px;
                }
            }
            &.title{
                dt{
                    width: 320px;
                }
            }
            &.info{
                dt{
                    width: 370px;
                }
                dd{
                    select.form_control{
                        width: 150px;
                    }
                    input.form_control{
                        width: 210px;
                        margin-left: 10px;
                    }
                }
            }
            &.room{
                dt{
                    width: 220px;
                }
            }
            &.date{
                dt{
                    width: 430px;
                }
                dd{
                    input.form_control{
                        margin-right: 10px;
                    }
                    .date_picker{
                        width: 165px;
                        padding-right: 47px;
                        background-image: url(/static/img_admin/ico_calendar2.png);
                        background-repeat: no-repeat;
                        background-position-y: 50%;
                        background-position-x: calc(100% - 20px);
                    }
                    .time{
                        width: 57px;
                    }
                    .dash{
                        margin-right: 10px;
                    }
                }
            }

            &.dateonly{
                padding-right: 0;
                dt{
                    width: 340px;
                }
                dd{
                    input.form_control{
                        margin-right: 10px;
                    }
                    .date_picker{
                        width: 165px;
                        padding-right: 47px;
                        background-image: url(/static/img_admin/ico_calendar2.png);
                        background-repeat: no-repeat;
                        background-position-y: 50%;
                        background-position-x: calc(100% - 20px);
                    }
                }
            }

            &.search{
                dt{
                    width: 460px;
                }
                dd{
                    select.form_control{
                        width: 150px;
                    }
                    input.form_control{
                        width: 210px;
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }
            
            &.autowidth{
                dt{
                    width: auto;
                }
                dd{
                    .form_control{
                        &.col_xs{
                            width: 90px;
                        }
                        &.col_sm{
                            width: 110px;
                        }   
                        &.col_md{
                            width: 130px;
                        }
                        
                    }
                }
            }

        }
    }

    .table_normal_list{
        &.light_gray{
            border-top: 1px solid #ebebeb;
        }
        table{
            tr{
                &.active{
                    td{
                        background-color: #fafafa;
                        .user_name{
                            &.gray{
                                background-color: #999999;
                                border-color: #999999;
                                color: #fff;
                            }
                            &.green{
                                background-color: #15aca7;
                                border-color: #15aca7;
                                color: #fff;
                            }
                        }
                    }
                }
                td{
                    .btn_xs{
                        margin-top: 3px;
                        margin-left: 3px;
                    }
                    .point{
                        display: block;
                        &.red{
                            color: #e9425c;
                        }
                        &.green{
                            color: #05ad8e;
                        }
                        &.blue{
                            color: #5985dc;
                        }
                    }
                    .form_wrap{
                        position: relative;
                        padding: 0;
                        input.form_control{
                            width: calc(100%);
                            border-radius: 3px;
                        }
                        .btn_sm{
                            position: absolute;
                            right: 0px;
                            top: 0;
                            min-width: 80px;
                            height: 36px;
                            line-height: 34px;
                            margin: 0;
                            border-radius: 0 3px 3px 0;
                        }
                    }
                    .radio_box{
                        label{
                            min-width: 40px;
                            width: 23%;
                            margin-left: 20px;
                        }
                    }
                    .btn_file_srch{
                        position: absolute;
                        right: 0;
                        top: 50%;
                        margin-top: -16px;
                        padding-right: 45px; 
                        background-image: url('/static/img_admin/btn_file_srch.png'); 
                        background-repeat: no-repeat;
                        background-position: 81px 7px;
                    }
                }
            }
        }
    }

    .table_state{
        border-bottom: 1px solid #ebebeb;
        table{
            tbody{
                tr{
                    &:nth-child(1){
                        th{
                            border-top: 0 none;
                        }
                    }
                    th{
                        border-top: 1px solid #ebebeb;
                    }
                    td{
                        border: 0;
                        padding: 5px 5px;
                        .user_state{
                            position: relative;
                            min-height: 68px;
                            height: 100%;
                            border: 1px solid #d1d1d1;
                            border-radius: 4px;
                            padding: 8px 16px;
                            .btn_wrap{
                                width: 100%;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                            ul{
                                display: flex;
                                justify-content: space-between;
                                line-height: 22px;
                                padding: 5px 0;
                                li{
                                    text-align: left;
                                    &:nth-child(2){
                                        text-align: right;
                                    }
                                    span, strong{
                                        display: block;
                                    }
                                    
                                }
                            }
                            .btn_xs{
                                padding: 0 10px;
                            }
                            &.type1{
                                background-color: #ffeff2;
                                border-color: #e5cdd0;
                            }
                            &.type2{
                                background-color: #d7f9f3;
                                border-color: #acd9d1;
                            }
                            &.type3{
                                background-color: #dff3fa;
                                border-color: #c6d9e0;
                            }
                            &.type4{
                                background-color: #f0f0f0;
                            }
                            &.type5{
                                border: 0 none;
                            }

                            &:hover{
                                border-color: #4e4d4d;
                                .user_detail{
                                    display: block;
                                }
                            }

                            .user_detail{
                                display: none;
                                position: absolute;
                                left: 0;
                                top: calc(100% + 5px);
                                padding: 10px 30px;
                                width: 282px;
                                background-color: #fff;
                                border: 1px solid #4e4d4d;
                                z-index: 10;
                                .lst_desc{
                                    display: block;
                                    li{
                                        display: flex;
                                        flex-flow: row nowrap;
                                        margin-top: 5px;
                                        text-align: left;
                                        &:first-child{
                                            margin-top: 0;
                                        }
                                        span{
                                            flex: 1;
                                            font-size: 13px;
                                            color: #666;
                                            &.tit{
                                                flex: 0 0 100px;
                                                color: #333;
                                            }
                                        }
                                    }
                                }

                                &.right{
                                    left: auto;
                                    right: 0;
                                }
                            }
                        }   /*user_state*/
                    }
                }
            }
        }
    }

    .file_name_upload{
        ul{
            margin-top: 20px;
            li{
                height: 50px;
                padding: 4px 0;
                margin-bottom: 10px;
                border: 1px solid #d1d1d1;
                border-radius: 4px;
                &::after{
                    content: '';
                    display: block;
                    clear: both;
                }
                .first{
                    float: left;
                    width: 61%;
                }
                .second{
                    display: inline-block;
                    width: 39%;
                    .form_control{
                        width: 114px;
                    }
                }
                label{
                    display: inline-block;
                    width: 122px;
                    padding-left: 40px;
                    border-right: 1px solid #ebebeb;
                    vertical-align: middle;
                }
                .form_control{
                    width: calc(100% - 122px);
                    border: 0 none;
                }
            }
        }
    }

    .btn_area{
        padding-bottom: 30px;
        border-bottom: 1px solid #ebebeb;
        &::after{
            content: '';
            display: block;
            clear: both;
        }
        .btn_sm{
            min-width: 120px;
            height: 40px;
            line-height: 40px;
            &.btn_outline{
                line-height: 38px;
            }
        }
        .btn_addline{
            &::before{
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: url("/static/img_admin/btn_addline.png") no-repeat 50%;
                margin-right: 8px;
            }
        }
        .btn_delline{
            &::before{
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: url("/static/img_admin/btn_delline.png") no-repeat 50%;
                margin-right: 8px;
            }
        }
    }

    .lst_status{
        display: flex;
        background-color: #f7f8f9;
        li{
            // float: left;
            // width: 25%;
            position: relative;
            flex-grow: 1;
            padding: 43px 30px 43px 75px;
            border-left: 1px solid #eaebec;
            background-repeat: no-repeat;
            background-position: 30px 50%;

            &:first-child{
                border: 0;
            }
            &:nth-child(1){
                background-image: url("/static/img_admin/basic_info_calendar.png");
            }
            &:nth-child(2){
                background-image: url("/static/img_admin/basic_info_build.png");
            }
            &:nth-child(3){
                background-image: url("/static/img_admin/basic_info_location.png");
            }
            &:nth-child(4){
                background-image: url("/static/img_admin/basic_info_check.png");
            }

            &.disabled{
                width: 100%;
                padding: 43px 0;
                background: 0 none;
                font-size: 16px;
                text-align: center;
            }

            strong{
                float: left;
                font-size: 16px;
                font-weight: 400;
                color: #999;
            }
            span{
                float: right;
                font-size: 16px;
                color: #333;
                &.room{
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    em{
                        display: block;
                        padding-bottom: 6px;
                    }
                }

            }
        }
        &.type2{
            li{
                &:nth-child(1){
                    background-image: url("/static/img_admin/basic_info_build.png");
                }
                &:nth-child(2){
                    background-image: url("/static/img_admin/basic_info_direction.png");
                }
                &:nth-child(3){
                    background-image: url("/static/img_admin/basic_info_stay.png");
                }
                &:nth-child(4){
                    background-image: url("/static/img_admin/basic_info_leave.png");
                }
                &:nth-child(5){
                    background-image: url("/static/img_admin/basic_info_rooms.png");
                }
            }
        }
    }
}

.pop_wrap{
    .process_list{
        dl{
            dt{
                font-size: 14px;
                color: #333;
                padding: 15px 0 10px;
                &:first-child{
                    padding-top: 0;
                }
            }
            dd{
                textarea.form_control{
                    margin: 0;
                    height: 180px;
                }
                select.form_control{
                    width: 218px;
                    height: 36px;
                    line-height: 36px;
                    &.year{
                        width: 114px;
                    }
                    &.month, &.day{
                        width: 94px;
                        margin-left: 10px;
                    }
                }
                input.form_control{
                    height: 36px;
                    line-height: 36px;
                }
                .radio_box{
                    margin-bottom: 10px;
                }
                .input_box{
                    select.form_control{
                        width: 110px;
                        margin-right: 10px;
                    }
                    input.form_control{
                        width: calc(100% - 120px);
                    }
                }
            }
            &.file_upload_table{
                margin: 30px 0;
                border-top: 1px solid #959595;
                border-bottom: 1px solid #ebebeb;
                dt{
                    padding: 12px 0;
                    background: #fafafa;
                    border-bottom: 1px solid #ebebeb;
                    font-weight: 500;
                    text-align: center;
                }
                dd{
                    margin: 5px 0 10px;
                    padding: 0 10px;
                }
            }
        }
    }    
    .request_date{
        padding-top: 25px;
        margin-bottom: 20px;
        border-top: 1px solid #959595;
        border-bottom: 1px solid #ebebeb;
        &::after{
            content: '';
            display: block;
            clear: both;
        }
        &:first-child{
            border-top: 0 none;
            padding-top: 5px;
        }
        li{
            float: left;
            padding: 0 60px 23px 0;
            strong{
                display: block;
                font-weight: 400;
            }
            span{
                display: block;    
                margin-top: 7px;
            }
        }
    }
}

#pop_period{
    .pop_wrap{
        width: 900px;
        .select_date{
            display: flex;
            flex-flow: row nowrap;
            border-top: 1px solid #ededed;
            border-bottom: 1px solid #ededed;
            margin-top: 24px;
        
            .date_start{
              flex: 0 0 50%;
            }
        
            .date_end{
              flex: 0 0 50%;
              border-left: 1px solid #ededed;
            }
        
            .img_datepicker{
              display: block;
              width: 100%;
            }
          }
        
        .result_area{
            padding: 0 30px;
            margin-top: 4px;
            .lst_date{
                li{
                    display: inline-block;
                    strong{
                        margin-right: 18px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #5985dc;
                        vertical-align: middle;
                    }
                    span{
                        margin-right: 30px;
                        font-size: 16px;
                        font-weight: 700;
                        color: #666;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    #container{
        .table_state {
            table{
                tbody {
                    tr {
                        td {
                            .user_state{
                                ul{
                                    flex-direction: column;
                                    li{
                                        text-align: left !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }    
        } 
    }
}
