@charset "utf-8";


#pop_assign_room{
    .pop_wrap{
        width: 1289px;
    }

    .group_floor{
        overflow-x: auto;
        .outer_floor{
            display: inline-flex;
            flex-flow: row nowrap;

            .lst_ho{
                flex: 0 0 160px;
                border-top: 1px solid #959595;
                border-bottom: 1px solid #ebebeb;
                border-left: 1px solid #ebebeb;
                &:first-child{
                    border-left: 0;
                }
                &.title_row{
                    border-left: 0;
                    li{
                        justify-content: left;
                        padding-left: 30px;
                        font-weight: 500 !important;
                        background-color: #fafafa;
                    }
                }
                li{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 10px;
                    height: 46px;
                    border-top: 1px solid #ebebeb;
                    &:first-child{
                        border-top: 0;
                        font-weight: 700;
                        background-color: #fafafa;
                    }
                }
            }
        }

        &.assign_room{
            .outer_floor {
                .lst_ho {
                    li{
                        flex-flow: column nowrap;
                        padding: 0;
                        height: 60px;
                        &:first-child{
                            height: 46px;
                        }

                        > p{
                            width: 100%;
                            text-align: center;
                            .rank{
                                padding-left: 5px;
                            }
                            .part{
                                padding-left: 10px;
                            }
                        }
                        > a{
                            display: block;
                            width: 100%;
                            height: 60px;
                            text-align: center;
                            line-height: 60px;
                            font-weight: 700;
                            background-color: #b0e3d9;
                            &.active{
                                background-color: #71ade6;
                                color: #fff;
                            }
                        }
                    }
                }

            }
        }
    }
}