@charset "utf-8";

#pop_select_location{
    .pop_wrap{
        width: 735px;
    }

    .items_area{
        overflow-y: auto;
        padding-top: 15px;
        max-height: 120px;
        .item{
            margin-bottom: 5px;
        }
    }

    .group_coordinate{
        position: relative;
        margin-top: 20px;
        padding-left: 29px;
        padding-top: 30px;
        .lst_top{
            display: flex;
            flex-flow: row nowrap;
            position: absolute;
            left: 29px;
            top: 0;
            right: 0;
            height: 31px;
            z-index: 1;
            border: 1px solid #969696;
            li{
                flex: 1;
                height: 29px;
                border-left: 1px solid #969696;
                text-align: center;
                line-height: 29px;
                &:first-child{
                    border-left: 0;
                }
            }
        }
        .lst_left{
            display: flex;
            flex-flow: column nowrap;
            position: absolute;
            left: 0;
            top: 30px;
            bottom: 0;
            width: 29px;
            border: 1px solid #d1d1d1;
            li{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top: 1px solid #d1d1d1;
                text-align: center;
                line-height: 29px;
                &:first-child{
                    border-top: 0;
                }
            }
        }
        .img_area{
            position: relative;
            border-right: 1px solid #d1d1d1;
            border-bottom: 1px solid #d1d1d1;
            img{
                display: block;
                width: 100%;
            }
            .lst_area{

                display: flex;
                flex-flow: row wrap;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                li{
                    position: relative;
                    flex: 1 1 20%;
                    border-left: 1px solid #d1d1d1;
                    border-top: 1px solid #d1d1d1;
                    &:nth-child(5n-4){
                        border-left: 0;
                    }
                    a{
                        display: block;
                        position: absolute;
                        left: -1px;
                        top: -1px;
                        right: -1px;
                        bottom: -1px;
                        z-index: 10;
                        &.active{
                            background-color: rgba(241,97,93,0.24);
                            border: 1px solid #ed8380;
                        }
                    }
                }
            }
        }
    }
}

#pop_select_spot{
    .pop_wrap{
        width: 840px;
    }

    .select_keyword{
        padding: 20px 0;
        .form_control{
            width: 140px;
            margin-right: 10px;
        }
    }

    .type_area{
        overflow: auto;
        margin-top: 20px;
        max-height: 257px;
    }

    .lst_floortype{
        display: inline-flex;
        flex-flow: row nowrap;
        margin-top: 4px;
        min-width: 500px;
        &:first-child{
            margin-top: 0;
        }
        li{
            flex: 0 0 70px;
            border-top: 1px solid #cfcfcf;
            border-left: 1px solid #fff;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                text-align: center;
                background-color: #e9e9e9;
                font-size: 14px;
                color: rgba(51,51,51,0.5);
                &:hover{
                    background-color: #d1d1d1;
                }
            }
        }


        &.type1{
            li {
                border-top: 1px solid #cfcfcf;

                a {
                    background-color: #e2faeb;

                    &.on {
                        font-weight: 700;
                        background-color: #c7ebd5;
                        color: #3a7f55;
                    }

                    &:hover {
                        background-color: #c7ebd5;
                        color: #3a7f55;
                    }
                }
            }
        }
        &.type2{
            li{
                border-top: 1px solid #adc7de;
                a{
                    background-color: #e0effb;
                    &.on{
                        font-weight: 700;
                        background-color: #c6dbec;
                        color: #2d6b9c;
                    }
                    &:hover{
                        background-color: #c6dbec;
                        color: #2d6b9c;
                    }
                }
            }
        }
        &.type3{
            li{
                border-top: 1px solid #cfcfcf;
                a{
                    background-color: #ffe1f6;
                    &.on{
                        font-weight: 700;
                        background-color: #edc5e1;
                        color: #7f456e;
                    }
                    &:hover{
                        background-color: #edc5e1;
                        color: #7f456e;
                    }
                }
            }
        }
        &.type4{
            li{
                border-top: 1px solid #beaee6;
                a {
                    background-color: #e3d7ff;
                    &.on {
                        font-weight: 700;
                        background-color: #d0c2f2;
                        color: #594689;
                    }
                    &:hover{
                        background-color: #d0c2f2;
                        color: #594689;
                    }
                }
            }
        }
        &.type5{
            li{
                border-top: 1px solid #e4cfa1;
                a{
                    background-color: #fff4dc;
                    &.on{
                        font-weight: 700;
                        background-color: #f1e1be;
                        color: #7f6733;
                    }
                    &:hover{
                        background-color: #f1e1be;
                        color: #7f6733;
                    }
                }
            }
        }
    }

    .txt_current{
        margin-top: 5px;
        padding: 10px 0;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #333;
        border-bottom: 1px solid #e1e1e1;
    }


    .function_area{
        padding: 10px 0 5px;
        text-align: right;
        .btn_reset{
            border:0;
            padding-left: 40px;
            padding-right: 0;
            text-align: left;
            background: url("/static/img/pc/ico_reset.png") no-repeat 15px 50%/16px;
        }
    }

    .items_area{
        overflow-y: auto;
        padding-top: 15px;
        max-height: 120px;
        .item{
            margin-bottom: 5px;
        }
    }
}

