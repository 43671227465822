/* noto-sans-kr-regular - latin_korean */
/* noto-sans-kr-300 - latin_korean */
@font-face {
    font-family: 'NotoSansKR';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/noto-sans-kr-v12-korean-300.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
    url('/fonts/noto-sans-kr-v12-korean-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/noto-sans-kr-v12-korean-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/noto-sans-kr-v12-korean-300.woff') format('woff'), /* Modern Browsers */
    url('/fonts/noto-sans-kr-v12-korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-400 - latin_korean */
@font-face {
    font-family: 'NotoSansKR';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/noto-sans-kr-v12-korean-regular.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
    url('/fonts/noto-sans-kr-v12-korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/noto-sans-kr-v12-korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/noto-sans-kr-v12-korean-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/noto-sans-kr-v12-korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-500 - latin_korean */
@font-face {
    font-family: 'NotoSansKR';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/noto-sans-kr-v12-korean-500.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
    url('/fonts/noto-sans-kr-v12-korean-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/noto-sans-kr-v12-korean-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/noto-sans-kr-v12-korean-500.woff') format('woff'), /* Modern Browsers */
    url('/fonts/noto-sans-kr-v12-korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - latin_korean */
@font-face {
    font-family: 'NotoSansKR';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/noto-sans-kr-v12-korean-700.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
    url('/fonts/noto-sans-kr-v12-korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/noto-sans-kr-v12-korean-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/noto-sans-kr-v12-korean-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/noto-sans-kr-v12-korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}


/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'),
    url('/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
    url('/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'),
    url('/fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* ModernH-Light */
@font-face {
    font-family: 'ModernH';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/ModernH-Light.eot'); /* IE9 Compat Modes */
    src: local('ModernH Light'), local('ModernH-Light'),
    url('/fonts/ModernH-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/ModernH-Light.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/ModernH-Light.woff') format('woff'), /* Modern Browsers */
    url('/fonts/ModernH-Light.svg#ModernH') format('svg'); /* Legacy iOS */
}

/* HDHarmony */
@font-face {
    font-family: 'HDHarmony';
    font-weight: 300;
    font-style: normal;
    src: url('/fonts/HDHarmonyL.eot?#iefix') format('embedded-opentype');
    src: local("☺"), url("/fonts/HDHarmonyL.woff") format("woff"), url("/fonts/HDHarmonyL.ttf") format("truetype");
}
@font-face {
    font-family: 'HDHarmony';
    font-weight: 400;
    font-style: normal;
    src: url('/fonts/HDHarmonyM.eot?#iefix') format('embedded-opentype');
    src: local("☺"), url("/fonts/HDHarmonyM.woff") format("woff"), url("/fonts/HDHarmonyM.ttf") format("truetype");
}
