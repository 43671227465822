@charset "utf-8";
//@import "../mixin";

#wrap.wrap_mobile {
    overflow: hidden;
    // 공통
    #mb_header{
        position: fixed;
        top: 0;
        left: 0;
        padding: 10px 20px;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #ededed;
        background-color: #fff;
        z-index: 100;
        h1{
            //@include ir;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 32px;
            height: 35px;
            a{
                display: block;
                height: 35px;
                background: transparent url("/static/img_mobile/ico_logo.png") no-repeat 0/32px;
            }
        }
        h2{
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            color: #222;
        }
        .btn_allmenu{
            //@include ir;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 38px;
            border: 0;
            background: #fff url("/static/img_mobile/ico_allmenu.png") no-repeat 50%/24px;
            cursor: pointer;
        }
        .select_place{
            display: none;
            position: absolute;
            right: 72px;
            top: 10px;
            width: 145px;
        }

        .mb_nav{
            overflow-y: auto;
            position: fixed;
            top: 0;
            right: 0;
            //left: 0;
            bottom: 0;
            width: 0;
            background-color: rgba(0,0,0,0);
            z-index: 110;
            transition: background-color 0.5s;

            .group_allmenu{
                position: fixed;
                top: 0;
                right: -283px;
                bottom: 0;
                padding-left: 62px;
                width: 283px;
                background-color: #fff;
                z-index: 110;
                transition: right 0.2s;

                .log_area{
                    position: relative;
                    height: 60px;
                    .btn_home{
                        //@include ir;
                        position: absolute;
                        left: -36px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 33px;
                        height: 27px;
                        border: 0;
                        background: #fff url("/static/img_mobile/ico_home.png") no-repeat 0/33px;
                        cursor: pointer;
                    }

                    .btn_close{
                        //@include ir;
                        position: absolute;
                        right: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 30px;
                        height: 38px;
                        border: 0;
                        background: #fff url("/static/img_mobile/ico_close_allmenu.png") no-repeat 50%/18px;
                        cursor: pointer;
                    }

                    .btn_logout{
                        position: absolute;
                        right: 60px;
                        top: 50%;
                        transform: translateY(-50%);
                        padding: 0 10px;
                        height: 30px;
                        border-radius: 15px;
                        border: 1px solid #666;
                        font-size: 16px;
                        font-weight: 700;
                        color: #333;
                        background-color: #fff;
                        cursor: pointer;
                    }
                }

                .user_area{
                    height: 99px;
                    padding: 13px 0 33px;
                    .desc{
                        position: relative;
                        line-height: 23px;
                        font-size: 16px;
                        color: #333;
                        .ico_user{
                            position: absolute;
                            left: -33px;
                            top: 0;
                            width: 25px;
                            height: 25px;
                            background: #fff url("/static/img_mobile/ico_user_allmenu.png") no-repeat 50%/25px;
                        }
                        &:after{
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -30px;
                            width: 30px;
                            height: 3px;
                            background-color: #05ad8e;
                        }
                    }
                }

                .lst_allmenu{
                    li{
                        border-bottom: 1px solid #eee;
                        a{
                            display: block;
                            height: 54px;
                            line-height: 54px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #222;
                            background: transparent url("/static/img_mobile/ico_link_allmenu.png") no-repeat 100%/9px;
                            background-position-x: calc(100% - 27px);
                        }
                    }
                }
            }
        }

        &.on{
            .mb_nav{
                width: 100%;
                background-color: rgba(0,0,0,0.55)
            }
            .group_allmenu{
                right: 0;
            }
        }
    }

    #container{
        margin: 0;
        padding: 60px 20px 50px;

        > .btn_more{
            margin-top: 30px;
            width: 100%;
            .ico_plus{
                display: inline-block;
                position: relative;
                top: -2px;
                vertical-align: middle;
                margin-left: 10px;
                width: 10px;
                height: 10px;
                background: url("/static/img_mobile/ico_plus.png") no-repeat 0 0/10px;
            }
        }
    }

    hr{
        margin: 20px -20px 0;
        height: 10px;
        border: 0;
        background-color: #f2f2f2;
        &.hr_inline{
            margin: 20px 0 0;
            height: 1px;
        }
    }

    .btn{
        font-size: 14px;
    }

    .form_control{
        &.datepicker{
            background: url("/static/img_mobile/ico_calender.png") no-repeat 100% 50%/13px;
            background-position-x: calc(100% - 15px);
        }
    }

    select.form_control{
        background-image: url("/static/img_mobile/ico_select.png");
        background-size: 13px;
        background-position-x: calc(100% - 15px);
        background-repeat: no-repeat;
        appearance: none;
        background-position-y: 50%;
    }

    .lst_warning{
        margin-top: 10px;
        li{
            padding-left: 14px;
            &:before{
                top: 5px;
                width: 9px;
                height: 10px;
                font-size: 13px;
                background: url("/static/img_mobile/ico_warning.png") no-repeat 0 0/ 9px;
            }
        }
    }

    .table_normal_list{
        margin-top: 30px;
        table{
            tbody{
                th,td{
                    height: 40px;
                    font-size: 13px;
                }
                th{
                    font-weight: 700;
                    color: #333;
                }
                td{
                    color: #666;
                }
            }
        }
        &.table_write {
            table{
                th, td{
                    padding: 5px 10px;
                }
            }
        }
    }

    .form_img{
        overflow-x: auto;
        margin-left: -20px;
        margin-right: -20px;
        padding: 0 20px;
        line-height: 0;
        white-space: nowrap;
        .img_area{
            overflow: hidden;
            display: inline-block;
            position: relative;
            margin-left: 10px;
            width: 70px;
            height: 70px;
            border-radius: 8px;
            background: #f5f5f5 url("/static/img_mobile/ico_camara.png") no-repeat 50%/27px;
            &:first-child{
                margin-left: 0;
            }
            img{
                display: block;
                width: 70px;
                height: 70px;
            }

            .btn_del{
                //@include ir;
                display: block;
                position: absolute;
                right: 0px;
                top: 0px;
                width: 20px;
                height: 20px;
                background: url("/static/img_mobile/ico_del_img.png") no-repeat 50%/10px;
            }
        }
    }

    .items_area{
        position: relative;
        display: block;
        padding: 15px 0 45px;
        border-top: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        .item{
            margin-right: 10px;
            margin-bottom: 10px;
        }
        .unit{
            position: absolute;
            bottom: 15px;
            right: 0;
            line-height: 14px;
            font-size: 12px;
            font-weight: 400;
            color: #999;
        }
    }

    // 개인정보 리스트
    .lst_userinfo{
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        margin-top: 30px;
        padding: 20px;
        background-color: #f7faff;

        li{
            flex: 0 0 40px;
            display: flex;
            align-items: center;
            padding: 0px;
            height: 40px;
            border-left: 0;
            border-bottom: 1px solid #dae1ed;

            &.user_area{
                flex: 1;
                display: flex;
                position: relative;
                margin-bottom: 15px;
                padding: 0;
                border-bottom: 0;
                .img_user{
                    overflow: hidden;
                    margin-right: 20px;
                    width: 50px;
                    height: 50px;
                    background: url("/static/img_mobile/ico_user.png") no-repeat 0 0/50px;
                    flex: 0 0 50px;
                    margin-right: 10px;
                }
                .username{
                    justify-content: flex-start;
                    height: 50px;
                    line-height: 50px;
                    font-size: 24px;
                    color: #333;
                }
                .state{
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 17px;
                    font-weight: 700;
                    color: #05ad8e;
                }
            }

            strong{
                flex: 0 0 30%;
                padding-right: 0px;
                font-size: 13px;
                font-weight: 400;
                color: #999;
            }
            span{
                flex: 0 0 70%;
                display: flex;
                justify-content: flex-end;
                font-size: 14px;
                color: #333;
            }
        }

    }

    // 모달 팝업
    .pop_modal{
        .pop_wrap{
            overflow-y: auto;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            padding-bottom: 100px;
            width: 100%;
            border-radius: 0;
            z-index: 200;

            .title_wrap{
                position: relative;
                margin: 0 20px;
                padding: 0;
                height: 60px;
                text-align: center;
                border-bottom-color: #ededed;
                h3{
                    height: 59px;
                    line-height: 59px;
                    font-family: NotoSansKR;
                    font-size: 18px;
                    font-weight: 700;
                    color: #222;
                }
                .pop_btn_close{
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 18px;
                    height: 18px;
                    background: url("/static/img_mobile/ico_close_modal.png") no-repeat 50%/18px;
                }
                .pop_btn_prev{
                    //@include ir;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 21px;
                    height: 21px;
                    border: 0;
                    background: url("/static/img_mobile/ico_prev.png") no-repeat 0 50%/12px;
                }
            }

            .contents_wrap{
                overflow: visible;
                max-height: unset;
                padding: 0 20px;

                .title_contents{
                    padding: 0;
                    border: 0;
                }
            }

            .button_wrap{
                //@include clearfix;
                position: fixed;
                bottom: 0;
                left: 0;
                padding: 0;
                width: 100%;
                z-index: 99;
                .btn{
                    width: 100%;
                    height: 50px;
                    border-radius: 0;
                    font-size: 16px;
                    &.w50{
                        float: left;
                        margin: 0;
                        width: 50%;
                    }
                }
            }
        }

        &.pop_dim_wrap{
            .pop_wrap{
                overflow: visible;
                position: absolute;
                left: 20px;
                right: 20px;
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
                padding: 0;
                width: auto;

                .contents_wrap{
                    padding: 0 20px;
                    .title_contents{
                        padding: 50px 20px 45px;
                        text-align: center;
                        font-size: 17px;
                        font-weight: 400;
                        color: #333;
                        border-bottom: 1px solid #959595;
                    }
                }

                .button_wrap{
                    position: relative;
                }
            }
        }
    }   /*pop_modal*/

    .pop_modal.pop_alert{
        padding: 0 20px;
        .pop_wrap{
            left: 20px;
            right: 20px;
            top: 50%;
            width: auto;
            padding: 50px 0 0;
            transform: translate(0, -50%);
            border-radius: 0;
            border: 1px solid #4e4d4d;

            .button_wrap{
                position: relative;
                padding: 30px 0;
            }
        }
    }   /*pop_alert*/

    // form
    .form_write{
        margin: 0;
        padding: 0;
        border: 0;
        .row{
            margin-top: 15px;
            &.col_3ea{
                .col{
                    &:first-child{
                        margin-left: 0;
                    }
                    float: left;
                    margin-left: 2%;
                    width: 32%;
                }
            }
        }

        .title_form{
            height: 34px;
            font-size: 13px;
            color: #222;
        }
        .form_control{
            padding: 0 20px;
            height: 38px;
            font-size: 13px;
            margin: 0;
            &.timepicker{
                width: 100px;
            }
        }
        p.form_control{
            border: 1px solid #d1d1d1;
            border-radius: 4px;
        }
        textarea{
            &.form_control{
                padding: 10px 20px;
                height: 138px;
            }
        }
        .btn_sm{
            height: 38px;
            line-height: 36px;

            &.btn_confirm{
                width: 100%;
            }
        }

        .form_phone{
            padding-right: 95px;
            .form_control{
                width: 100%;
            }
            .btn{
                position: absolute;
                top: 0;
                right: 0;
                width: 85px;
            }
        }

        .form_confirm{
            position: relative;
            padding-right: 114px;
            .form_control{
                width: 100%;
            }
            .btn{
                position: absolute;
                top: 0;
                right: 0;
                padding: 0;
                width: 104px;
            }
        }

        .form_img{
            overflow-x: auto;
            margin-left: -20px;
            margin-right: -20px;
            padding: 0 20px;
            line-height: 0;
            white-space: nowrap;
            .img_area{
                overflow: hidden;
                display: inline-block;
                position: relative;
                margin-left: 10px;
                width: 70px;
                height: 70px;
                border-radius: 8px;
                background: #f5f5f5 url("/static/img_mobile/ico_camara.png") no-repeat 50%/27px;
                &:first-child{
                    margin-left: 0;
                }
                img{
                    display: block;
                    width: 70px;
                    height: 70px;
                }

                .btn_del{
                    //@include ir;
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 20px;
                    height: 20px;
                    background: url("/static/img_mobile/ico_del_img.png") no-repeat 50%/10px;
                }
            }
        }

        .form_function{
            .btn{
                min-width: unset;
                width: 100%;
                padding: 0 5px;
                .ico_camera{
                    display: inline-block;
                    position: relative;
                    top: -2px;
                    margin-left: 15px;
                    vertical-align: middle;
                    width: 27px;
                    height: 24px;
                    background: url("/static/img_mobile/ico_camara_btn.png") no-repeat 0 0/27px;
                }
                .ico_file{
                    display: inline-block;
                    position: relative;
                    top: -2px;
                    margin-left: 15px;
                    vertical-align: middle;
                    width: 20px;
                    height: 27px;
                    background: url("/static/img_mobile/ico_doc_btn.png") no-repeat 0 0/20px;
                }
            }
            .wrap_file{
                width: 100%;
            }
        }

        .form_radio{
            padding: 0;

            .input_radio{
                margin-left: 30px;
                &:first-child{
                    margin-left: 0;
                }
            }
        }

        .form_search{
            position: relative;
            .form_control{
                padding-right: 50px;
            }
            .btn_srch{
                //@include ir;
                position: absolute;
                right: 10px;
                top: 1px;
                width: 36px;
                height: 36px;
                border: 0;
                background: #fff url("/static/img_mobile/ico_search.png") no-repeat 50%/14px;
                cursor: pointer;
            }
        }
    }   /*form_write*/

    // title
    .group_title{
        position: relative;
        margin-top: 20px;
        //border-bottom: 1px solid #959595;

        .title_contents{
            height: 18px;
            line-height: 18px;
            font-size: 17px;
            font-weight: 400;
            color: #222;
        }

        .right_area{
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-size: 13px;
            color: #333;
        }
    }   /*group_title*/
    .group_title + hr{
        margin: 15px 0 0;
        height: 1px;
        background-color: #959595;
    }

    // tab
    .tab_contents {display: none;}
    .tab_contents.on {display: block;}
    .group_tab{
        position: relative;
        min-height: 40px;
        margin-top: 30px;
    }
    .lst_tab{
        display: flex;
        flex-flow: row nowrap;
        li{
            flex: 1;
            &:first-child a{
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &:last-child a{
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            a{
                display: block;
                margin-left: -1px;
                padding: 0;
                height:40px;
                border: 1px solid #ededed;
                font-size:14px;
                line-height:40px;
                color:#666;
                text-align:center;
                background-color: #fafafa;
                &.on{
                    border: 0;
                    color:#fff !important;
                    background-color: #05ad8e;
                    border-color: #05ad8e;
                    font-weight: 700;
                }
            }
        }
    }

    .box_scroll_x{
        //min-height: unset;
        line-height: 40px;
        margin: 20px -20px 0;
        padding: 0 20px;
        .lst_tab{
            display: inline-flex;
            vertical-align: top;
            margin-right: 20px;
            li{
                flex: unset;
                a{
                    min-width: 82px;
                    padding: 0 15px;
                    white-space: nowrap;
                }
            }
        }
    }

    // 로그인
    &.wrap_login {
        overflow: visible;
        min-width: unset;
        min-height: 0;
        background: #fff;

        #container{
            padding: 0 30px 0;
            height: auto;

            .title{
                line-height: 34px;
                font-size: 30px;
                color: #333;
            }

            .row{
                height: auto;
                .col{
                    padding: 50px 0;
                    background: transparent;

                    .group_login{
                        width: 100%;
                    }
                }
            }

            hr{
                margin-left: -30px;
                margin-right: -30px;
            }

            .form_login{
                width: auto;
                margin-top: 30px;
                li{
                    padding-left: 75px;
                    label{
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 75px;
                        height: 45px;
                        line-height: 45px;
                        font-family: NotoSansKR;
                        font-size: 13px;
                        font-weight: 700;
                        color: #222;
                    }

                    .form_control{
                        height: 45px;
                        border-color: #ddd;
                        //color: #d9d9d9;
                        font-size: 13px;
                        text-align: left;
                    }

                    .time{
                        font-size: 13px;
                        font-weight: 700;
                        color: #f1615d;
                    }
                }


            }
            .lst_membership{
                margin-top: 0;
            }
            .submit{
                .btn_lg{
                    font-size: 16px;
                    font-weight: 700;
                }
                .lst_srch{
                    margin-top: 25px;
                    .btn_link{
                        font-size: 14px;
                        color: #333;
                    }
                }

            }
        }
    }

    // 아이디, 비밀번호 찾기 팝업
    #pop_srch_id{
        .lst_msg{
            margin-top: 0;
            padding: 25px 20px;
            border-top: 1px solid #959595;
            border-bottom: 1px solid #ddd;
            dd{
                padding-top: 0;
                font-size: 13px;
            }
        }

        .lst_basicinfo{
            margin-top: 20px;
            dt{
                padding-bottom: 15px;
                height: auto;
                line-height: unset;
                font-size: 17px;
                border-top: 0;
            }
            dd{
                padding: 10px 20px;
                ul{
                    li{
                        strong{
                            font-size: 14px;
                        }
                        span{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }   /*pop_srch_id*/

    // 회원가입 완료
    #pop_join_complete{
        .lst_basicinfo{
            margin-top: 20px;
            dt{
                font-size: 15px;
            }
            dd{
                padding: 10px 20px;
                ul{
                    li{
                        strong{
                            font-size: 14px;
                        }
                        span{
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .lst_complete {
            margin-left: -20px;
            margin-right: -20px;
            padding: 130px 20px 50px;
            text-align: center;
            background: #f7faff url("/static/img_mobile/ico_complete.png") no-repeat 50% 50px/60px;
            dt {
                font-family: HDHarmony;
                font-size: 24px;
                font-weight: 300;
                color: #333;
            }
            dd {
                padding-top: 25px;
                line-height: 24px;
                font-size: 13px;
                color: #666;
            }
        }
    }   /*pop_join_complete*/

    // 메인
    &.wrap_mobile_main{
        #mb_header{
            .select_place{
                display: block;
            }
            h2{
                display: none;
            }
        }
        min-width: unset;
        .lst_counts {
            display: flex;
            flex-flow: row nowrap;
            margin-top: 15px;
            padding: 20px 0;
            border: 1px solid #ebebeb;
            border-top-color: #959595;
            li {
                flex: 1 1;
                text-align: center;
                border-left: 1px solid #ebebeb;
                &:first-child{
                    border-left: 0;
                }
                strong {
                    display: block;
                    line-height: 26px;
                    font-size: 24px;
                    font-weight: 700;
                    color: #333;
                }
                span {
                    display: block;
                    margin-top: 15px;
                    color: #666;
                    font-size: 13px;
                }
            }
        }   /*lst_counts*/

        .group_manage{
            .lst_manage{
                display: flex;
                flex-flow: column nowrap;
                position: relative;
                margin-top: 7px;
                padding: 15px 20px;
                border: 1px solid #ebebeb;
                border-radius: 5px;
                &:hover{
                    border-color: #959595;
                }
                &:first-child{
                    margin-top: 10px;
                }

                li{
                    line-height: 0;
                    &:nth-child(1){
                        strong{
                            padding-right: 8px;
                            font-weight: 700;
                            color: #333;
                        }
                        span{
                            padding-left: 8px;
                            font-weight: 700;
                            color: #333;
                            border-left: 1px solid #333;
                        }
                    }
                    &:nth-child(2){
                        margin-top: 10px;
                        span{
                            padding-left: 5px;
                        }
                    }
                    &:nth-child(3){
                        position: absolute;
                        right: 20px;
                        top: 15px;
                        text-align: center;
                        strong{
                            display: block;
                        }
                        span{
                            margin-top: 10px;
                        }
                    }

                    &:first-child{
                        border-top: 0;
                    }
                    strong{
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 15px;
                        font-size: 13px;
                        font-weight: 400;
                        color: #222;
                    }
                    span{
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 15px;
                        font-size: 13px;
                        color: #666;
                        &.txt_ellipsis{
                            display: inline-block !important;
                            max-width: 60%;
                        }
                    }
                }
            }   /*lst_manage*/
        }   /*group_manage*/

    }   /*wrap_mobile_main*/

    //입숙처리 리스트
    .lst_checkin{
        margin-top: 20px;
        dt{
            ul{
                display: flex;
                flex-flow: row nowrap;
                li{
                    padding: 0 10px;
                    line-height: 16px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333;
                    border-left: 1px solid #ddd;
                    &:first-child{
                        padding-left: 0;
                        font-weight: 700;
                        border-left: 0;
                    }
                }
            }
        }
        dd{
            margin-top: 10px;
            padding: 20px 20px;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            ul{
                li{
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    height: 30px;
                    strong{
                        flex: 1;
                        font-size: 14px;
                        font-weight: 400;
                        color: #999;
                    }
                    span{
                        flex: 1;
                        text-align: right;
                        font-size: 14px;
                        font-weight: 700;
                        color: #333;
                    }
                }
            }
        }
    }

    //데이터가 없습니다
    .blank_area{
        padding: 142px 0 72px;
        text-align: center;
        background: #f7faff url("/static/img_mobile/ico_undefind.png") no-repeat 50% 61px/51px;
        p{
            font-family: HDHarmony;
            font-size: 24px;
            color: #333;
            font-weight: 400;
        }
    }

    //voc 리스트
    .lst_voc{
        padding-top: 10px;
        li{
            margin-top: 10px;
            a{
                display: block;
                padding: 20px 20px 10px;

                border: 1px solid #ebebeb;
                border-radius: 10px;

                .desc_area{
                    .roomtype{
                        display: inline-block;
                        padding: 0 10px 0 38px;
                        height: 26px;
                        line-height: 24px;
                        border: 1px solid #b4e2da;
                        border-radius: 13px;
                        font-size: 13px;
                        color: #059f83;
                        background: #f4fbfa url("/static/img_mobile/ico_roomtype1.png") no-repeat 12px 50%/21px;
                        &.type1{
                            background-image: url("/static/img_mobile/ico_roomtype1.png");
                            background-position-x: 12px;
                            background-size: 21px;
                        }
                        &.type2{
                            background-image: url("/static/img_mobile/ico_roomtype2.png");
                            background-position-x: 12px;
                            background-size: 21px;
                        }
                        &.type3{
                            background-image: url("/static/img_mobile/ico_roomtype3.png");
                            background-position-x: 12px;
                            background-size: 20px;
                        }
                        &.type4{
                            background-image: url("/static/img_mobile/ico_roomtype4.png");
                            background-position-x: 12px;
                            background-size: 19px;
                        }
                        &.type5{
                            background-image: url("/static/img_mobile/ico_roomtype5.png");
                            background-position-x: 12px;
                            background-size: 19px;
                        }
                    }
                    .title{
                        //@include txt_ellipsis;
                        padding: 15px 0;
                        font-size: 15px;
                        color: #333;
                    }
                }
                .info_area{
                    //@include clearfix;
                    padding: 15px 0 0;
                    border-top: 1px solid #ebebeb;
                    font-size: 13px;
                    color: #999;
                    .type{
                        float: left;
                    }
                    .time{
                        float: right;
                    }
                }
            }
        }
    }

    .lst_faq{
        padding-top: 10px;
        li{
            margin-top: 10px;
            border: 1px solid #ebebeb;
            border-radius: 5px;

            .info_area{
                //@include clearfix;
                position: relative;
                margin: -1px -1px 0;
                padding: 10px 15px;
                border: 1px solid #b4e2da;
                background-color: #f4fbfa;
                border-radius: 5px 5px 0 0;
                .type{
                    float: left;
                    display: inline-block;
                    padding-left: 25px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 13px;
                    font-weight: 700;
                    color: #059f83;
                    background: url("/static/img_mobile/ico_voc.png") no-repeat 0 50%/18px;
                }
                .desc{
                    float: left;
                    display: inline-block;
                    margin-left: 17px;
                    padding-left: 38px;
                    border-left: 1px solid #b4e2da;
                    height: 20px;
                    line-height: 20px;
                    font-size: 13px;
                    font-weight: 700;
                    color: #444;
                    background: url("/static/img_mobile/ico_in.png") no-repeat 12px 50%/17px;
                    &.case2{
                        background: url("/static/img_mobile/ico_out.png") no-repeat 12px 50%/17px;
                    }
                    &.case3{
                        background: url("/static/img_mobile/ico_doc.png") no-repeat 12px 50%/16px;
                    }
                    &.case4{
                        background: url("/static/img_mobile/ico_complaint.png") no-repeat 12px 50%/19px;
                    }
                    &.case5{
                        background: url("/static/img_mobile/ico_etc.png") no-repeat 12px 50%/20px;
                    }
                }
                .time{
                    float: right;
                    height: 20px;
                    line-height: 20px;
                    font-size: 13px;
                    color: #666666;
                }
            }
            .desc_area{
                //@include txt_ellipsis;
                padding: 12px 15px;
                font-size: 15px;
                color: #666666;
            }
        }
    }

    //숙소 외 장소보기 팝업
    #pop_select_location{
        .pop_wrap{
            //width: 840px;
        }

        .items_area{
            overflow-y: auto;
            margin-top: 15px;
            padding-top: 15px;
            max-height: 120px;
            .item{
                margin-bottom: 5px;
            }
        }

        .group_coordinate{
            position: relative;
            min-width: 600px;
            margin-top: 30px;
            padding-left: 29px;
            padding-top: 30px;
            .lst_top{
                display: flex;
                flex-flow: row nowrap;
                position: absolute;
                left: 29px;
                top: 0;
                right: 0;
                height: 31px;
                z-index: 1;
                border: 1px solid #969696;
                li{
                    flex: 1;
                    height: 29px;
                    border-left: 1px solid #969696;
                    text-align: center;
                    line-height: 29px;
                    &:first-child{
                        border-left: 0;
                    }
                }
            }
            .lst_left{
                display: flex;
                flex-flow: column nowrap;
                position: absolute;
                left: 0;
                top: 30px;
                bottom: 0;
                width: 29px;
                border: 1px solid #d1d1d1;
                li{
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top: 1px solid #d1d1d1;
                    text-align: center;
                    line-height: 29px;
                    &:first-child{
                        border-top: 0;
                    }
                }
            }
            .img_area{
                position: relative;
                border-right: 1px solid #d1d1d1;
                border-bottom: 1px solid #d1d1d1;
                img{
                    display: block;
                    width: 100%;
                }
                .lst_area{

                    display: flex;
                    flex-flow: row wrap;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    li{
                        position: relative;
                        flex: 1 1 20%;
                        border-left: 1px solid #d1d1d1;
                        border-top: 1px solid #d1d1d1;
                        &:nth-child(5n-4){
                            border-left: 0;
                        }
                        a{
                            display: block;
                            position: absolute;
                            left: -1px;
                            top: -1px;
                            right: -1px;
                            bottom: -1px;
                            z-index: 10;
                            &.active{
                                background-color: rgba(241,97,93,0.24);
                                border: 1px solid #ed8380;
                            }
                        }
                    }
                }
            }
        }
    }

    //숙소 내 장소보기 팝업
    #pop_select_spot{
        .pop_wrap{
            //width: 840px;
        }

        .select_keyword{
            padding: 20px 0;
            .form_control{
                width: 140px;
                margin-right: 10px;
            }
        }

        .type_area{
            overflow: auto;
            margin-top: 30px;
            max-height: 257px;
        }

        .lst_floortype{
            display: inline-flex;
            flex-flow: row nowrap;
            margin-top: 4px;
            min-width: 500px;
            &:first-child{
                margin-top: 0;
            }
            li{
                flex: 0 0 70px;
                border-top: 1px solid #cfcfcf;
                border-left: 1px solid #fff;
                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 80px;
                    text-align: center;
                    background-color: #e9e9e9;
                    font-size: 14px;
                    color: rgba(51,51,51,0.5);
                    &:hover{
                        background-color: #d1d1d1;
                    }
                }
            }


            &.type1{
                li {
                    border-top: 1px solid #cfcfcf;

                    a {
                        background-color: #e2faeb;

                        &.on {
                            font-weight: 700;
                            background-color: #c7ebd5;
                            color: #3a7f55;
                        }

                        &:hover {
                            background-color: #c7ebd5;
                            color: #3a7f55;
                        }
                    }
                }
            }
            &.type2{
                li{
                    border-top: 1px solid #adc7de;
                    a{
                        background-color: #e0effb;
                        &.on{
                            font-weight: 700;
                            background-color: #c6dbec;
                            color: #2d6b9c;
                        }
                        &:hover{
                            background-color: #c6dbec;
                            color: #2d6b9c;
                        }
                    }
                }
            }
            &.type3{
                li{
                    border-top: 1px solid #cfcfcf;
                    a{
                        background-color: #ffe1f6;
                        &.on{
                            font-weight: 700;
                            background-color: #edc5e1;
                            color: #7f456e;
                        }
                        &:hover{
                            background-color: #edc5e1;
                            color: #7f456e;
                        }
                    }
                }
            }
            &.type4{
                li{
                    border-top: 1px solid #beaee6;
                    a {
                        background-color: #e3d7ff;
                        &.on {
                            font-weight: 700;
                            background-color: #d0c2f2;
                            color: #594689;
                        }
                        &:hover{
                            background-color: #d0c2f2;
                            color: #594689;
                        }
                    }
                }
            }
            &.type5{
                li{
                    border-top: 1px solid #e4cfa1;
                    a{
                        background-color: #fff4dc;
                        &.on{
                            font-weight: 700;
                            background-color: #f1e1be;
                            color: #7f6733;
                        }
                        &:hover{
                            background-color: #f1e1be;
                            color: #7f6733;
                        }
                    }
                }
            }
        }

        .txt_current{
            margin-top: 5px;
            padding: 10px 0;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #333;
            border-bottom: 1px solid #e1e1e1;
        }


        .function_area{
            padding: 10px 0 5px;
            text-align: right;
            .btn_reset{
                border:0;
                padding-left: 40px;
                padding-right: 0;
                text-align: left;
                background: url("/static/img/pc/ico_reset.png") no-repeat 15px 50%/16px;
            }
        }

        .items_area{
            overflow-y: auto;
            margin-top: 15px;
            padding-top: 15px;
            max-height: 120px;
            .item{
                margin-bottom: 5px;
            }
        }
    }

    //이미지 팝업
    #pop_imageslide{
        .pop_btn_close{
            //@include ir;
            position: fixed;
            right: 20px;
            top: 20px;
            width: 20px;
            height: 20px;
            background: url("/static/img_mobile/ico_close_modal_white.png") no-repeat 0 0/20px;
            z-index: 100;
        }
        .pop_wrap{
            left: 0;
            right: 0;
            background-color: transparent;

            .contents_wrap{
                padding: 0;

                .slide{
                    position: relative;

                    .lst_slide{
                        display: flex;
                        flex-flow: row nowrap;
                        overflow: hidden;
                        width: 100%;
                        white-space: nowrap;
                        li{
                            flex: 0 0 100%;
                            min-width: 100%;
                            img{
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                    .pagination{
                        position: absolute;
                        bottom: 30px;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        li{
                            display: inline-block;
                            margin: 0 5px;
                            a{
                                //@include ir;
                                display: block;
                                width: 8px;
                                height: 8px;
                                border-radius: 4px;
                                background: #fff;
                                opacity: 0.3;
                                &.active{
                                    width: 25px;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    // 보안 정책 및 유의사항 팝업
    #pop_security {
        .pop_wrap{
            padding: 0;
            .title_wrap {
                border: 0 none;
            }
            .contents_wrap{
                .txt_security{
                    max-height: 200px;
                    overflow: auto;
                    padding: 10px;
                    line-height: 20px;
                    border: 1px solid #d1d1d1;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #333;
                    vertical-align: middle;
                }
                .right_area{
                    margin-top: 10px;
                    text-align: right;
                }
            }
            .button_wrap{
                padding: 20px 0 0;
                .w100{
                    width: 100%;
                }
            }
        }
    }

    /* 담당자 설정 팝업 */
    #pop_set_manager{
        .group_title{
            .title_contents{
                margin: 0;
            }
        }
        .detail_list{
            margin: 12px 0 0;
            padding: 10px 0 0;
            border: 0 none;
            border-top: 1px solid #959595;
            .col{
                .col_content{
                    height: auto;
                    max-height: 243px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #959595;
                    li{
                        height: 40px;
                        line-height: 38px;
                        p{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
            .form_control{
                margin:0;
            }
        }
        .btn_wrap{
            margin-top: 15px;
            .btn_prev{
                background: url(/static/img_mobile/detail_list_prev.png) no-repeat 23px 50%/16px;
                padding-left: 45px;
                font-weight: 700;
            }
            .btn_next{
                background: url(/static/img_mobile/detail_list_next.png) no-repeat 23px 50%/16px;
                padding-left: 45px;
                font-weight: 700;
            }
        }
    }

    .pop_wrap .detail_list .col.w100{width: 100%;}

    // 에러
    &.error{
        .pop_modal{
            .pop_wrap{
                .title_wrap{
                    margin: 0;
                }
            }
        }
        .error_message{
            .ico_error{
                top: 60px;
                height: 274px;
                line-height: 35px;
                padding-top: 140px;
                background-image: url(/static/img_mobile/ico_error.png);
                background-position-y: 50px;
                background-size: 100px;
                font-size: 24px;
                &.type2{
                    background-image: url(/static/img_mobile/ico_error2.png);
                    background-position-y: 44px;
                    background-size: 76px;
                }
                span{
                    display: block;
                    font-size: 20px;
                }
            }
            .detail{
                padding: 311px 0 0;
                line-height: 21px;
                span{
                    display: inline;
                }
            }
        }
    }

    .fixed_text{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        min-height: 40px;
        line-height: 20px;
        padding: 10px 20px;
        background-color: #fff;
        border-top: 1px solid #d9d9d9;
        word-break: break-all;
    }
}   /*wrap_mobile*/