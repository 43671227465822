@charset "utf-8";

#pop_assign_roomtype{
    .pop_wrap{
        width: 1100px;
    }

    .box_scroll_y{
        overflow-x: auto;
        max-height: 284px;
    }

    .lst_room{
        display: flex;
        flex-flow: row;
        li{
            display: flex;
            flex-flow: column;
            flex: 0 0 68px;
            position: relative;
            margin: 0px 0 0 -1px;
            max-width: 68px;
            border:1px solid #fff;
            border-top: 0;

            &:first-child{
                margin-left: 0;
            }

            .num{
                margin: 0;
                text-align: center;
                height: 25px;
                line-height: 25px;
                background-color: #666;
                font-size: 14px;
                font-weight: 700;
                color: #fff;
                span{
                    padding-left: 5px;
                    font-weight: 400;
                }
            }

            .room{
                flex: 1 1 58px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 8px;
                text-align: left;
                background-color: #b0e3d9;
                border-top: 1px solid #fff;
                font-size: 14px;
                font-weight: 700;
                color: #666;
                &:hover{
                    background-color: #71ade6;
                }
                &:nth-of-type(1){
                    border-top: 0;
                }
                &.disabled{
                    background: #e9e9e9 url("/static/img_admin/bg_room_disabled.png") no-repeat 0 0;
                    background-size: 100% 100%;
                    cursor: not-allowed;
                }
                &.blank{
                    background: #f0f0f0 url("/static/img_admin/ico_disabled.png") no-repeat 0 0;
                }
                &.on{
                    position: relative;
                    z-index: 10;
                    //margin: 0 -1px 0 -1px;
                    //border: 3px solid #ffac5b;
                    background-color: #71ade6;
                }

                span{
                    display: block;
                    line-height: 20px;
                    font-size: 13px;
                    color: #999;
                    &.name{
                        font-size: 14px;
                        color: #333;
                    }
                }

            }
        }
    }
}
