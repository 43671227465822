@charset "utf-8";

@mixin clearfix{
	&:before{
        content: " ";
        display: table;
    }
    &:after{
        content: " ";
        display: table;
        clear: both;
    }
}