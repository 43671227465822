@charset "utf-8";

/* login */
#login{position: absolute; left: 50%; top: 50%; margin: -400px 0 0 -230px; padding: 50px; width: 460px; background-color: #fff; /* box-shadow: 0px 6px 10px 0 rgba(0, 0, 0, 0.05); */}
#login::before{content:'';position:absolute;top:-67px;left:0;display:block;width:167px;height:32px;background:url(/img_admin/logo.png) no-repeat;}
#login h1{text-align: center; font-size: 30px; font-weight: 400; color: #000; letter-spacing: -0.25px;}
#login h1 strong{color: #3348ae;font-weight:700;}
#login .form_control{border: 1px solid #ccc;}
#login .lst_login{margin-top: 44px;}
#login .lst_login li{margin-top: 13px;}
#login .lst_login li:first-child{margin-top: 0;}
#login .lst_login li label{display: block;}
#login .lst_login li label .form_control{padding-left: 20px; height: 60px; font-size: 16px;}
#login .lst_login li.check_area{margin-top: 0; padding: 20px;}
#login .lst_login li label.input_check .label_text{padding-left: 30px; font-size: 14px; color: #333;}
#login h2{padding-bottom: 10px; font-size: 16px; font-weight: 400;}
#login .lst_certify{padding: 15px 18px; background-color: #eef6fc;}
#login .lst_certify .phone_area{}
#login .lst_certify .phone_area label{float: left; margin-left: 5px; width: 214px; height: 36px;}
#login .lst_certify .phone_area label:first-child{margin-left: 0;}
#login .lst_certify .phone_area .form_control{height: 36px;}
#login .lst_certify .phone_area .btn_send{margin-left: 5px; padding: 0; width: 105px; height: 36px;}

#login .lst_certify .certify_area{margin-top: 5px;}
#login .lst_certify .certify_area label{float: left; width: 214px; height: 36px;}
#login .lst_certify .certify_area .form_control{float: left; height: 36px;}
#login .lst_certify .certify_area span{float: left; width: 60px; height: 36px; line-height: 36px; text-align: center; font-size: 14px; color: #757575;}
#login .lst_certify .certify_area .btn_confirm{padding: 0; min-width: 0; width: 50px; height: 36px; border:0; color: #fff; background-color: #060c23;}
#login .btn_login{margin-top: 30px; width: 100%; height: 60px; font-size: 20px; color: #fff;}
#login .copyright{position:absolute;bottom:-20px;left:0;width: 100%; font-size:13px;line-height:20px;color:#555;text-align: center;}

//휴대폰 본인 인증 팝업
#pop_confirm_phone .pop_wrap{width: 381px;}
#pop_confirm_phone .lst_certify{}
#pop_confirm_phone .lst_certify .phone_area{}
#pop_confirm_phone .lst_certify .phone_area label{float: left; margin-left: 5px; width: 190px; height: 36px;}
#pop_confirm_phone .lst_certify .phone_area label:first-child{margin-left: 0;}
#pop_confirm_phone .lst_certify .phone_area .form_control{height: 36px;}
#pop_confirm_phone .lst_certify .phone_area .btn_send{margin-left: 5px; padding: 0; width: 105px; height: 36px;}

#pop_confirm_phone .lst_certify .certify_area{margin-top: 5px;}
#pop_confirm_phone .lst_certify .certify_area label{float: left; width: 190px; height: 36px;}
#pop_confirm_phone .lst_certify .certify_area .form_control{float: left; height: 36px;}
#pop_confirm_phone .lst_certify .certify_area span{float: left; width: 60px; height: 36px; line-height: 36px; text-align: center; font-size: 14px; font-weight: 700; color: #ff7132;}
#pop_confirm_phone .lst_certify .certify_area .btn_confirm{padding: 0; min-width: 0; width: 50px; height: 36px; border:0; color: #fff; background-color: #060c23;}

.loading {position: relative; width: 100%; height: 100%; background-color: #f8f8f9;}
.loading h1{position: absolute; top: -60px; left: 0; width: 244px; height: 39px; background:url(/static/img_admin/logo.png) no-repeat 0 0; font-size: 0;}
.loading .loading_message{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 671px; min-height: 416px; border: 1px solid #e5e5e5; background-color: #fff; text-align: center;}
.loading .loading_message .ico_fail{font-family: HDHarmony; padding: 187px 0 68px; background: url(/static/img_admin/ico_fail.png) no-repeat 50% 87px; line-height: 30px; font-size: 20px;}
.loading .loading_message .ico_wait{font-family: HDHarmony; padding: 227px 0 0; background: url(/static/img_admin/loding.gif) no-repeat 50% 185px; line-height: 30px; font-size: 20px;}

.error #header{border-bottom: 0 none; background-color: transparent;}
.error #header h1 a{background-color: transparent;}
.error{text-align: center;}
.error .error_message .ico_error{position: absolute; left: 0; top: 0; width: 100%; height: 487px; font-family: HDHarmony; padding: 267px 0 0; background: #f7faff url(/static/img_admin/ico_error.png) no-repeat 50% 180px; line-height: 50px; font-size: 36px;}
.error .error_message .ico_error.type2{background: #f7faff url(/static/img_admin/ico_error2.png) no-repeat 50% 170px;}
.error .error_message .ico_error span{display: block;}
.error .error_message .detail{padding: 556px 0 56px; line-height: 24px; font-size: 14px; color: #666;}
.error .error_message .btn_lg{min-width: 160px;}
