@charset "UTF-8";
@import "./mixin.scss";


.loading::before{content: '';position: fixed;left: 0;top: 0;right: 0;bottom: 0;background: url(/static/img_admin/pc_loading.gif) no-repeat 50%;z-index: 999;font-size: 0;}
.wrap_mobile.loading::before{content: '';position: fixed;left: 0;top: 0;right: 0;bottom: 0;background: url(/static/img_mobile/mobile_loading.gif) no-repeat 50%/38px;z-index: 999;font-size: 0;}

/*=============================================
	Datepicker
=============================================*/
.ui-datepicker.ui-widget-content {border:1px solid #d7d7d7;z-index:999999 !important;font-family:Arial,sans-serif;}
.ui-datepicker.ui-widget-content .ui-datepicker-header{border:none;background:none;font-weight:400;}
.ui-datepicker.ui-widget-content .ui-datepicker-prev,
.ui-datepicker.ui-widget-content .ui-datepicker-next{cursor:pointer;}
.ui-datepicker.ui-widget-content .ui-datepicker-prev::before{content:'〈';display:inline-block;width:1.6em;height:1.8em;line-height:1.8em;text-align:center;font-size:1.1em;font-weight:900;}
.ui-datepicker.ui-widget-content .ui-datepicker-next::after{content:'〉';display:inline-block;width:1.6em;height:1.8em;line-height:1.8em;text-align:center;font-size:1.1em;font-weight:900;}
.ui-datepicker.ui-widget-content .ui-datepicker-prev span,
.ui-datepicker.ui-widget-content .ui-datepicker-next span{content:'b';background:none;}
.ui-datepicker.ui-widget-content th{color:#ff9a19;}
.ui-datepicker.ui-widget-content td a, 
.ui-datepicker.ui-widget-content td span{border:0;border-radius:4px;background:none;text-align:center;height:2em;line-height:2em;}
.ui-datepicker.ui-widget-content .ui-state-hover{background:#f0f0f0}
.ui-datepicker.ui-widget-content .ui-state-highlight{color:#4EB5E6;}
.ui-datepicker.ui-widget-content .ui-state-active{background:#5cc4ef;color:#fff;}
.ui-datepicker.ui-widget-content select.ui-datepicker-month, .ui-datepicker.ui-widget-content select.ui-datepicker-year{vertical-align:middle;margin:0 3px;padding:2px 0;font-size:13px;line-height:21px;font-family:"맑은 고딕";text-align:center;border:1px solid #ddd;}

.datepicker_searchwrap{display:inline-block; vertical-align: middle;}
.datepicker_searchwrap li{float:left;}
.datepicker_searchwrap > div{float:left; width: 165px;}
.datepicker_searchwrap .dash{float:left; padding: 0 5px; line-height: 36px;}
.datepicker_searchwrap .datepicker_connect{padding:0 5px;font-size:18px;color:#444;line-height:40px;}
.datepicker_searchwrap input{float: left; width:130px;padding:0 30px 0 13px;background:#fff url(/static/img_admin/ico_search_cal.png) no-repeat 92.7% 50%;font-size:14px;}


input.datepicker{width: 100%; background:#fff url(/static/img_admin/ico_calendar2.png) no-repeat 100% 50%; background-position-x: calc(100% - 10px);}
input.datepicker + .dash{margin: 0 10px;}

/*=============================================
	Datepicker2
=============================================*/
#calendarSet_wrap{
   border-radius: 2px;
   box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
   position: absolute;
   background-color: #fff;
   border: 1px solid rgba(0,0,0,0.12);
   top: 36px;
   left: 0;
   z-index: 999;
}
#calendarSet{
   border: 0 none;
   border-top: 1px solid rgba(0,0,0,0.12);
   margin-top: 40px;
}
.calendarSet_btn_close{
   position: absolute;
   right: 20px;
   top: 13px;
   cursor: pointer;
   width: 13px;
   height: 13px;
   border: 0 none;
   background: transparent url(/static/img_admin/ico_close_autocomplete.png) no-repeat 0 0;
   font-size: 0;
}
#calendarSet.e-calendar .e-content th{padding: 0; height: 30px; text-align: center; background-color: transparent; border: 0 none; color: rgba(0,0,0,0.54); font-size: 13px;}
#calendarSet.e-calendar .e-content td{text-align: center; border: 0 none;}
#calendarSet.e-calendar .e-calendar .e-header{display: block;}
.e-calendar .e-content td.e-today:hover span.e-day, 
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, 
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day, 
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day, 
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, 
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day, 
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day{background-color: #b51148 ; color: #fff ;opacity: 1; border: 0 none; box-shadow: none;}


/*=============================================
	Form 기본형태
=============================================*/
.form_control{width:100%;height:40px;line-height: 38px;padding:0 10px;border:1px solid #d1d1d1; border-radius: 4px; font-size:14px;color:#333;vertical-align: middle;-webkit-appearance: none;}
.form_control::placeholder{color: #b2b2b2; font-size: 14px;}
.form_control.form_sm{height: 36px; font-size: 14px;}
.form_control.form_lg{height: 48px; font-size: 14px;}
.form_control::-ms-clear{display: none;}
.form_control:disabled{background-color: #fafafa !important; color: #b1b1b1 !important;opacity: 1; -webkit-text-fill-color: inherit;}
input.form_control:read-only{background-color:#f8f8f8;}
input.form_control[readonly]{background-color:#f8f8f8;}
textarea.form_control{resize:none;margin: 5px 0;padding:10px;height:100px;line-height: 18px;text-align:left;}
.textarea{overflow-y:auto;margin:10px;height:100px;line-height: 18px;text-align:left;}
p.form_control{padding: 0;border: 0;line-height: 36px;}
select.form_control{-webkit-appearance: none; -moz-appearance: none; appearance: none; padding-left: 20px; padding-right: 40px; background: #fff url("/static/img_admin/ico_selectbox.png") no-repeat 95% 50%; background-position-x: calc(100% - 20px);}
/* IE 10, 11 */
select.form_control::-ms-expand {display: none;}
select.form_control:disabled{color: red;}

.form_inline .form_control{width: 165px;}
.form_inline .form_control + .form_control{margin-left: 10px;}
.form_inline .form_control.time{width: 59px; text-align: center;}
.form_inline > div{display: inline-block;}
.form_inline > div + .form_control{margin-left: 10px;}
.form_inline > div + div{margin-left: 10px;}

/*=============================================
	Checkbox, RadioButton
=============================================*/
/* 체크박스 */
.input_check{position:relative;display:inline-block;min-height:20px;margin-left:12px;line-height:20px;color:#666;font-size:14px;font-weight:normal;vertical-align:middle}
.input_check:first-child{margin-left:0}
.input_check > input[type="checkbox"]{position:absolute;top:0;right:0;height:auto;line-height:normal;opacity:0;visibility:hidden;}
.input_check > input[type="checkbox"]:focus + .label_text{outline: 1px dotted;outline-color: #A6C7FF;outline:-webkit-focus-ring-color auto 5px;}
.input_check > .label_text{display:inline-block;padding-left:28px;background:url(/static/img_admin/ico_checkbox_off.png) no-repeat 0 50% / 20px;font-size:14px;color:#333;vertical-align:top;cursor:pointer;}
.input_check > input[type="checkbox"]:checked + .label_text{background-image:url(/static/img_admin/ico_checkbox_bgon.png)}

.input_check.check_individual > .label_text{background:url(/static/img_admin/ico_checkbox_off1.png) no-repeat 0 50%;}
.input_check.check_individual > input[type="checkbox"]:checked + .label_text{background-image:url(/static/img_admin/ico_checkbox_on1.png)}
.input_check.check_table > .label_text{background:url(/static/img_admin/ico_checkbox_off2.png) no-repeat 0 50%;}
.input_check.check_table > input[type="checkbox"]:checked + .label_text{background-image:url(/static/img_admin/ico_checkbox_on2.png)}
.input_check.check_term > .label_text{background:url(/static/img_admin/ico_check_notoutline.png) no-repeat 0 50%;}
.input_check.check_term > input[type="checkbox"]:checked + .label_text{background-image:url(/static/img_admin/ico_check_notoutline_on.png)}


.search_deal_wait{padding-right:22px;background:url(/static/img_admin/ico_deal_wait.png) no-repeat 100% 50%;line-height:24px;}
.search_deal_nego{margin-left:20px;padding-right:22px;background:url(/static/img_admin/ico_deal_nego.png) no-repeat 100% 80%;line-height:24px;}

/* .input_check.pop_notext{cursor:pointer;} */
.input_check.pop_notext .label_text{padding-left:0;width:20px;height:20px;text-indent:-9999px;}

/* 라디오 */
.input_radio{position:relative;display:inline-block;min-height:20px;margin-left:12px;line-height:20px;color:#666;font-size:14px;font-weight:normal;vertical-align:middle}
.input_radio:first-child{margin-left:0}
.input_radio > input[type="radio"]{position:absolute;top:0;left:0;height:auto;line-height:normal;opacity:0;visibility:hidden;}
.input_radio > input[type="radio"]:focus + .label_text{outline: 1px dotted;outline-color: #A6C7FF;outline:-webkit-focus-ring-color auto 5px;}
.input_radio > .label_text{display:inline-block;padding-left:28px;background:url(/static/img_admin/ico_radio_off.png) no-repeat 0 50% / 18px;font-size:14px;color:#333;vertical-align:top;cursor:pointer;}
.input_radio > input[type="radio"]:checked + .label_text{background-image:url(/static/img_admin/ico_radio_on.png)}

.input_radio.pop_notext .label_text{padding-left:0;width:20px;height:20px;text-indent:-9999px;}

/*=============================================
	Selectbox
=============================================*/
.select_css{display:block;position:relative;max-width:100%;margin:0;background:#fff url(/static/img_admin/ico_selectcss.png) no-repeat;background-position: calc(100% - 10px) center;-moz-appearance:none;-webkit-appearance:none;appearance:none;cursor:pointer;}
.select_css::after {position:absolute;top:0;right:0;content:'';display:inline-block;width:12px;height:7px;background:#666;}
.select_css::-ms-expand{display:none}
.select_css option{font-weight:normal;}
/* Disabled styles */
.select_css:disabled,
.select_css[aria-disabled=true]{color:#aaa;background-color: #d4cece;cursor:default;}

.select_css.search_select{width:230px;}
.select_css.select_box{width:174px; display: inline-block;}

/*=============================================
	File Upload
=============================================*/
.wrap_file {position:relative;display:inline-block;width:422px;}
.wrap_file.w100 {width:100%;}
.wrap_file.w100 .upload_name{width: calc(100% - 100px);}
.wrap_file input[type="file"]{position:absolute;overflow:hidden;width:1px;height:1px;padding:0;margin:-1px;border:0;opacity:0}
.wrap_file .upload_name{display:inline-block;width:300px;height:36px;line-height:36px;vertical-align:middle;background-color:#fff;-webkit-appearance:none;-moz-appearance:none;appearance:none}
.wrap_file .btn.btn_sm{margin-left: 10px; height:36px;line-height:36px;}
.outline{outline: 1px dotted;outline-color: #A6C7FF;outline:-webkit-focus-ring-color auto 5px;}
.wrap_file.only_btn .btn{position: relative;right: auto;top: auto;border-radius: 4px;margin:0;}
.btn_fileuploade_close{display:inline-block;position:absolute;top:0;right:100px;width:36px;height:36px;padding:0;border:0;background: url(/static/img_admin/ico_close_autocomplete.png) no-repeat 50% 50%;text-indent:-9999px;cursor:pointer;}

.wrap_file .btn.btn_sm.btn_file{padding-right: 44px; min-width: unset; width: 112px; background: url(/static/img_admin/ico_page.png) no-repeat 0 50%; background-position-x: calc(100% - 20px); border: 1px solid #aaa;}

/*=============================================
	Search Form
=============================================*/
.group_search{padding: 25px 0; width:100%;background-color: #f7f8f9;font-size:0;vertical-align:middle; text-align: center;}
.search_form{display:inline-block; position: relative; padding-right: 85px;}
.search_form input[type="text"]{width:480px;vertical-align:middle;}
.search_form select{width:200px;margin-right:5px;vertical-align:middle;}
.search_form .btn_search{position: absolute; right: 0; top: 0; width: 80px; min-width: unset; height: 40px; line-height: 40px; background: #35354b; vertical-align:middle; border-radius: 4px; font-size: 14px; color: #fff;}
.search_form input.and_search{width: 150px; margin-left: 10px;}
.search_form input.and_search:first-of-type{margin-left: 0;}
.search_form .and_search_btn{display: inline-block;}
.search_form .and_search_btn button{margin-left: 10px; height: 36px; line-height: 36px;}

.link_area{position: relative; padding-left: 40px;}
.link_area .set_http{position: absolute; left: 10px; top: 50%; margin: -8px;}
.table_normal_list .desc_area::before{content: "";display: table;}
.table_normal_list .desc_area::after{content: "";display: table;clear: both;}
.table_normal_list .desc_area li {position: relative; float: left;}
.table_normal_list .desc_area li:first-child::before{display: none;}
.table_normal_list .desc_area li::before{content: '';display: inline-block;width: 1px;height: 13px;background-color: #4e4e4e;margin: -2px 10px 0;vertical-align: middle;}

/*=============================================
	write form
=============================================*/
.form_write {padding: 30px 50px;border-top: 1px solid #555555;border-bottom: 1px solid #ebebeb;margin-top: 20px; }
.form_write.form_select {padding: 30px 0;border-bottom: 0; }
.form_write .lst_warning {margin-top: 15px; }
.form_write .lst_help {margin-top: 20px; }
.form_write .row {margin-top: 30px; }
.form_write .row:before {content: " ";display: table; }
.form_write .row:after {content: " ";display: table;clear: both; }
.form_write .row:first-child {margin-top: 0; }
.form_write .row .col {float: left;width: 48%; }
.form_write .row .col.w100 {width: 100%; }
.form_write .row .col:nth-child(2) {float: right; }
.form_write .title_form {display: block;padding-bottom: 10px;font-size: 14px;color: #333; }
.form_write .form_phone{position: relative;}
.form_write .form_phone:before {content: " ";display: table; }
.form_write .form_phone:after {content: " ";display: table;clear: both; }
.form_write .form_phone .form_control {float: left;width: 80%; }
.form_write .form_phone .btn {min-width: auto;padding: 0;float: right;width: 18%; }
.form_write .form_phone .time {position: absolute;top: 0;right: 25%; line-height: 36px; font-size: 14px;color: #333;}
.form_write .form_email:before {content: " ";display: table; }
.form_write .form_email:after {content: " ";display: table;clear: both; }
.form_write .form_email .dash {float: left;width: 6%;text-align: center;font-size: 14px;color: #333;line-height: 40px; }
.form_write .form_email .form_control {float: left;width: 47%; }
.form_write .form_email .form_domain {float: left;padding-left: 20px;font-size: 16px;color: #333;line-height: 50px; }
.form_write .form_email select.form_control {float: right; }
.form_write .form_radio {padding: 20px 0; }
.form_write .form_terms {font-size: 14px;color: #777; }
.form_write .form_terms h4 {padding-top: 30px;font-size: 16px;color: #333;font-weight: 400; }
.form_write .form_terms p {margin-top: 20px; }
.form_write .form_terms .term_top {margin-top: 0; }
.form_write .emp_red {display: none;vertical-align: top;height: 18px;position: relative;padding-right: 10px;color: #dcdce0; }
.form_write .emp_red::before {content: '';display: block;position: absolute;right: 0;top: 0;width: 4px;height: 4px;background-color: #da4561;-webkit-border-radius: 50;-moz-border-radius: 50%;border-radius: 50%; }

.form_write.form_confirm{background-color: #fafafa; text-align: center;}
.form_write.form_confirm .col{display: inline-block; float: none; margin: 0 auto; width:auto;}
.form_write.form_confirm .col label{float: left; padding-right: 20px; padding-bottom: 0; line-height: 36px;}
.form_write.form_confirm .col .form_phone{float: left; width: 450px;}

/*=============================================
	autocomplete
=============================================*/
.autocomplete_area{display: none; position: absolute; right: 0; top: 35px; width: 240px; background-color: #fff; border: 1px solid #ddd; z-index: 10; box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);}
.autocomplete_area.on{display: block;}
.autocomplete_area .btn_close{display: block; position: absolute; top: -36px; right: 0; width: 36px; height: 36px; background: url(/static/img_admin/ico_close_autocomplete.png) no-repeat 50% 50%;}
.autocomplete_area .lst_auto li a{display: block; padding: 0 10px; height: 36px; line-height: 36px; text-align: left; font-size: 15px;}
.autocomplete_area .lst_auto li a:hover{background-color: #f2f2f2; color: #3348ae;}

/*=============================================
	Loader Icon
=============================================*/
.lds-spinner {color: official;display: inline-block;position: relative; margin: 0 auto; width: 64px;height: 64px;}
.lds-spinner div {transform-origin: 32px 32px;animation: lds-spinner 1.2s linear infinite;}
.lds-spinner div:after {content: " ";display: block;position: absolute;top: 3px;left: 29px;width: 5px;height: 14px;border-radius: 20%;background: #ccc;}
.lds-spinner div:nth-child(1) {transform: rotate(0deg);animation-delay: -1.1s;}
.lds-spinner div:nth-child(2) {transform: rotate(30deg);animation-delay: -1s;}
.lds-spinner div:nth-child(3) {transform: rotate(60deg);animation-delay: -0.9s;}
.lds-spinner div:nth-child(4) {transform: rotate(90deg);animation-delay: -0.8s;}
.lds-spinner div:nth-child(5) {transform: rotate(120deg);animation-delay: -0.7s;}
.lds-spinner div:nth-child(6) {transform: rotate(150deg);animation-delay: -0.6s;}
.lds-spinner div:nth-child(7) {transform: rotate(180deg);animation-delay: -0.5s;}
.lds-spinner div:nth-child(8) {transform: rotate(210deg);animation-delay: -0.4s;}
.lds-spinner div:nth-child(9) {transform: rotate(240deg);animation-delay: -0.3s;}
.lds-spinner div:nth-child(10) {transform: rotate(270deg);animation-delay: -0.2s;}
.lds-spinner div:nth-child(11) {transform: rotate(300deg);animation-delay: -0.1s;}
.lds-spinner div:nth-child(12) {transform: rotate(330deg);animation-delay: 0s;}
@keyframes lds-spinner {
   0% {  opacity: 1;}
   100% {  opacity: 0;}
}



/*=============================================
	일반형태 게시판(List)
=============================================*/
.group_tableinfo{position: relative; margin-bottom: 10px;}
.group_tableinfo:after{content: ''; display: block; clear: both; float: none;}
.group_tableinfo .num_total{float: left; height: 36px; line-height: 36px; font-size: 14px; color: #000;}
.group_tableinfo .num_total em{font-size: 15px; color:#000;}
.group_tableinfo .num_total em strong{font-size: 19px; font-weight: 700;}

.group_tableinfo .lst_help{float: left; margin-left: 10px;}
.group_tableinfo .lst_help li{height: 36px; line-height: 36px; font-size: 15px; color: #757575;}

.group_tableinfo .srch_area{float: right;}
.group_tableinfo .srch_area label{display: inline-block; width: auto; vertical-align: middle;}
.group_tableinfo .srch_area .btn_srch{display: inline-block; position: relative; margin: 0; width: 36px; height: 36px; vertical-align: middle; background: #fff url(/static/img_admin/ico_search2.png) no-repeat 50% 50%; border: 1px solid #ddd; -webkit-transition:box-shadow;transition:box-shadow; transition-duration: 0.2s;cursor:pointer;}
.group_tableinfo .srch_area .btn_srch:hover{box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);}
.group_tableinfo .srch_area .select_css.form_control{width:140px;display:inline-block;vertical-align:middle;background-size: 10px 5px;}
.group_tableinfo .srch_area .select_css.form_control + label{width:315px;}

.table_normal_list{border-top:1px solid #959595;}
.table_normal_list table{width:100%;border-collapse: collapse;border-spacing: 0;table-layout:fixed;}
.table_normal_list table caption{position: absolute;font-size: 0;left: -99999px;}
.table_normal_list table th, .table_normal_list table td{height:48px; padding: 5px 10px;}
.table_normal_list table th{text-align: center; border-right:1px solid #ebebeb; border-bottom:1px solid #ebebeb; font-size: 14px; font-weight: 500; color: #333; background:#fafafa;}
.table_normal_list table th .sort{padding-right: 15px; background: url(/static/img_admin/ico_sort.png) no-repeat 100% 50%; white-space: nowrap;}
.table_normal_list table th .sort.on{background: url(/static/img_admin/ico_sort_on.png) no-repeat 100% 50%;}
.table_normal_list table td{position: relative; text-align: center; border-right:1px solid #ebebeb; border-bottom:1px solid #ebebeb; font-size: 14px; font-weight: 400; color: #666; word-wrap: break-word;}
.table_normal_list table th:last-child{border-right:0;}
.table_normal_list table td:last-child{border-right:0;}
.table_normal_list table th.bdl{border-left: 1px solid #e1e3eb;}
.table_normal_list table td.br0{border-right: 0 none;}
.table_normal_list table td.bdl{border-left: 1px solid #e1e3eb;}

.table_normal_list tbody td.blank_area{padding: 50px 0; height: auto; text-align: center; background-color: #f2f2f2;}
.table_normal_list tbody td.blank_area.type_white{background-color:#fff;}
.table_normal_list tbody td.blank_area .txt_blank{padding: 76px 0 0; font-size: 18px; color: #333; background: url(/static/img_admin/ico_warning_table.png) no-repeat 50% 0;}
.table_normal_list tbody td.blank_area .txt_blank + button{margin-top: 20px;}
.table_normal_list table td.check{background-color: #fafafa;}
.table_normal_list table td .btn_file2{display: inline-block; margin: 0 5px; width: 32px; height: 32px; background: url(/static/img_admin/ico_file2.png) no-repeat 0 0; vertical-align: middle; cursor: default;}
.table_normal_list table td .btn_file{display: inline-block; margin: 0 5px; width: 27px; height: 23px; background: url(/static/img_admin/ico_file.png) no-repeat 0 0; vertical-align: middle; cursor: default;}
.table_normal_list table td .btn_file.blank{background-image: url(/static/img_admin/ico_file_blank.png); cursor: default;}
.table_normal_list table td .btn_image{display: inline-block; margin: 0 5px; width: 25px; height: 25px; background: url(/static/img_admin/ico_image.png) no-repeat 0 0; vertical-align: middle; cursor: default;}
.table_normal_list table td .btn_image.blank{background-image: url(/static/img_admin/ico_image_blank.png); cursor: default;}
.table_normal_list table td .btn_sm{margin: 3px 0; padding: 0 15px; min-width: 90px;}
.table_normal_list table td .btn_sm + .btn_sm{margin-left: 5px;}
.table_normal_list table td .btn_sm.w50{padding: 0; min-width: 42px;}
.table_normal_list table td .item{margin-top: 2px; margin-bottom: 2px;}

.table_normal_list table td .form_control{height: 36px;}

.table_normal_list.table_dong table td{
   padding: 0;}
.table_normal_list.table_dong table td a.on{display: block;
   height: 49px; line-height: 49px; background-color: #b0e3d9;}
.table_normal_list.table_dong table td a.active{background-color: #71ade6;color: #fff;}

.table_normal_list.event_on table tr:nth-child(2n) td{background-color: #fafafa;}
.table_normal_list.event_on table tr:hover td{background-color: #f7faff;}
.table_normal_list.event_on table tr.active td{background-color: #f7faff;}


.table_normal_list table tr.active td .user_name{background-color: #5985dc; border-color: #5985dc; color: #fff;}
.table_normal_list table tr.active td .user_name.gray{background-color: #999; border-color: #999;}
.table_normal_list table tr.active td .btn_sm{background-color: #2b3053; color: #fff;}
.table_normal_list.table_write table th,
.table_normal_list.table_write table td{text-align: left; padding: 5px 20px;}
.table_normal_list.table_write table tr td .btn_sm{margin-top: 0; margin-bottom: 0; min-width: 70px;}
.table_normal_list.table_write table tr td .btn_xs{margin-top: 0; margin-bottom: 0; min-width: 70px;}
.table_normal_list.table_write table tr td textarea.form_control{margin-top: 0; margin-bottom: 0; min-height: 140px;}
.table_normal_list.table_write table tr td .images_area{padding: 20px 0;}
.table_normal_list.table_write table tr td .wrap_file + .images_area{margin-left: -20px; margin-right: -20px; margin-top: 5px; padding: 10px 20px; min-height: 40px; border-top: 1px solid #ebebeb;}
.table_normal_list.table_write table tr td .wrap_file + .items_area{margin-left: -20px; margin-right: -20px; margin-top: 5px; padding: 10px 20px 2px; min-height: 40px; border-top: 1px solid #ebebeb;}
.table_normal_list.table_write table tr td .btn_srch + .items_area{display:block; margin-left: -20px; margin-right: -20px; margin-top: 5px; padding: 10px 20px 2px; min-height: 40px; border-top: 1px solid #ebebeb;}
.table_normal_list.table_write table tr td .contents_area{padding: 20px 0; min-height: 100px;}
.table_normal_list.table_write table tr td .item{margin-bottom: 3px;}

.table_normal_list.table_write tbody td.blank_area{padding: 50px 0; height: auto; text-align: center; background-color: #f2f2f2;}
.table_normal_list.table_write tbody td.blank_area.type_white{background-color:#fff;}
.table_normal_list.table_write tbody td.blank_area .txt_blank{padding: 76px 0 0; font-size: 18px; color: #333; background: url(/static/img_admin/ico_warning_table.png) no-repeat 50% 0;}
.table_normal_list.table_write tbody td.blank_area .txt_blank + button{margin-top: 20px;}

.table_normal_list table td .text_area{height: 100px;}
/*.table_normal_list.table_write table td{text-align: left; padding: 10px 20px;}*/
.table_normal_list.table_write.form table td{padding: 6px 10px;}
.table_normal_list table td .text_area{height: 317px;}
/* .table_normal_list table td .btn_area{position: absolute; right: 0; top: 50%; margin-top: -19px;} */
.table_normal_list table td select.form_control{padding: 0 10px;}
.table_normal_list table tr td.disabled{color: #ccc;}

.table_normal_list table tfoot tr th{background-color: #fef8f8;}
.table_normal_list table tfoot tr td{background-color: #fef8f8; font-size: 16px; color: #333333; font-weight: 700;}
.table_normal_list table .tfoot th{background-color: #fef8f8;}
.table_normal_list table .tfoot td{background-color: #fef8f8; font-size: 16px; color: #333333; font-weight: 700;}

.table_normal_list.check_list table tr th,
.table_normal_list.check_list table tr td{height: 30px; text-align: center;}

.board_paging{position: relative; padding-top:30px;text-align:center}
.board_paging .right_area{position: absolute; bottom: 0; right: 0;}
.board_paging .left_area{position: absolute; bottom: 0; left: 0;}
.board_paging ul{display:inline-block}
.board_paging ul::after{content:'';display:block;clear:both}
.board_paging li{float:left;margin: 0 3px;}
/*.board_paging li:last-child{margin-right:0}*/
.board_paging li a{display:inline-block;width:30px;height:30px;text-align:center;color:#666;font-size:14px;line-height:30px;}
.board_paging li a.active{border:0; font-weight: 700; color:#2b3053; border-bottom: 2px solid #2b3053;}
.board_paging li .paging_first,
.board_paging li .paging_prev,
.board_paging li .paging_next,
.board_paging li .paging_last{text-indent:-9999px;background:no-repeat center}
.board_paging li .paging_first{background-image:url('/static/img_admin/ico_paging_first.png')}
.board_paging li .paging_prev{background-image:url('/static/img_admin/ico_paging_prev.png')}
.board_paging li .paging_next{background-image:url('/static/img_admin/ico_paging_next.png')}
.board_paging li .paging_last{background-image:url('/static/img_admin/ico_paging_last.png')}
.board_paging .btn_write{position: absolute; right: 0; bottom: 0;}

.lst_select{position: relative; margin-top: 30px;}
.lst_select::after{content: ''; display: block; clear: both;}
.lst_select li{float: left; min-width: 100px; margin-left: 10px;}
.lst_select li:first-child{margin-left: 0; min-width: 140px;}
.lst_select .form_control.num{width: 140px; position: absolute; right: 0; top: 0;}

/* table_list */
.table_list{overflow: auto;}
.table_list table{margin-left: -1px; width: 100%; display: table; table-layout: fixed; border-collapse: collapse; border-style: 0; border-top: 1px solid #333;}
.table_list table tr th{display: table-cell; padding: 10px; height: 55px; background-color: #edf1f5; font-size:14px; font-weight: 500; color: #333; background-color: #fafafa; border-left: 1px solid #ebebeb; border-bottom: 1px solid #ebebeb;}
.table_list table tr td{height: 55px; padding: 0 10px; background-color: #fff; border-left: 1px solid #ebebeb; border-bottom: 1px solid #ebebeb; text-align: center; font-size: 14px; color: #666;}
.table_list table tfoot tr th,.table_default table tfoot tr td{background-color: #fffde8; border-bottom: 1px solid #e6e6e6;}

.table_list table tr:nth-child(2n) td{background-color: #fafafa;}
.table_list table tr:hover td{background-color: #f7faff;}
.table_list table tr.active td{background-color: #f7faff;}
.table_list table tr.selected td{background-color: #71ade6;color: #fff;}


/*=============================================
	일반형태 게시판(View)
=============================================*/

/*.table_normalView table{width:100%;border-top:1px solid #000;border-bottom:1px solid #aaa;table-layout:fixed;border-collapse: collapse;border-spacing:0;}
.table_normalView table caption{display:none;}
.table_normalView thead td{padding: 0 20px; border-bottom:1px solid #ddd;}
.table_normalView thead td.title_area{font-size: 16px; color: #333;}
.table_normalView table thead th{height:49px;border-bottom:1px solid #ddd;font-size:14px;font-weight:400; color: #333; background-color: #f2f2f2;}
.table_normalView table tbody th{font-size:14px;font-weight:400; color: #333; background-color: #f2f2f2;}
.table_normalView tbody td{padding: 22px 34px; height:59px; text-align: left; border-top:1px solid #ddd;font-size:14px;color:#555; word-break: break-all;}
.table_normalView td a.btn_download{display: inline-block;margin-left: 10px;padding-left: 29px;height: 29px;line-height: 29px;font-size: 14px;color: #3348ae;text-decoration: underline;background: url(/static/img_admin/ico_file.png) no-repeat 0 50%;}
.table_normalView .submit_area{position: relative; padding: 25px 0 0; text-align: right;}
.table_normalView .submit_area .btn_list{position: absolute; left: 0; bottom: 0;}
.table_normalView .submit_area .btn_list_right{position: absolute; right: 0; bottom: 0;}
.table_normalView th.vat{padding: 22px 0;}
.table_normalView th.vat span{display: inline-block; line-height: 24px;}*/





/*=============================================
	일반형태 게시판(Write)
=============================================*/

.table_normalWrite table{width:100%;border-top:1px solid #000;border-bottom:1px solid #aaa;table-layout:fixed;border-collapse: collapse;border-spacing:0;}
.table_normalWrite table caption{display:none;}
.table_normalWrite table th{height:49px;border-bottom:1px solid #ddd;font-size:14px;font-weight:400; color: #333; background-color: #f2f2f2;}
.table_normalWrite thead td{padding: 0 20px; border-bottom:1px solid #ddd;}
.table_normalWrite tbody td{padding: 10px 20px; text-align: left; border-top:1px solid #ddd;font-size:14px;color:#555;}
.table_normalWrite tbody td .form_control{padding: 10px; height: 275px;}
/* .table_normalWrite td .wrap_file{width: 100%;}
.table_normalWrite td .wrap_file .upload_name{width: 285px;}     */
.table_normalWrite td .wrap_file .btn_sm{padding: 0;}

.table_normalWrite .submit_area{position: relative; padding: 25px 0 0; text-align: right;}
.table_normalWrite .submit_area .btn_list{position: absolute; left: 0; bottom: 0;}


/*=============================================
	아코디언 게시판(FAQ형태)
=============================================*/

/* .table_accordion{} */
.table_accordion .group_title{height: 40px; border-top: 1px solid #000;}
.table_accordion .group_title li{float: left; line-height: 39px; text-align: center; font-size: 14px; color: #333;}
.table_accordion .group_title li:nth-child(1){width: 11.3%;}
.table_accordion .group_title li:nth-child(2){width: 66.9%;}
.table_accordion .group_title li:nth-child(3){width: 11.3%;}
.table_accordion .group_title li:nth-child(4){width: 10.5%;}

.table_accordion .group_desc {border-top: 1px solid #aaa; border-bottom: 1px solid #aaa;}
.table_accordion .group_desc dt{position: relative; padding: 0; border-top: 1px solid #ddd; -webkit-transition:box-shadow;transition:box-shadow; transition-duration: 0.2s;}
.table_accordion .group_desc dt:first-child{border-top: 0;}
.table_accordion .group_desc dt .lst_title li{float: left; position: relative; height: 50px; line-height: 49px; text-align: center; font-size: 14px; color: #555;}
.table_accordion .group_desc dt .lst_title li.title_area{font-size: 15px;}
.table_accordion .group_desc dt .lst_title li a{overflow: hidden; display: block; text-overflow: ellipsis; white-space: nowrap;}
.table_accordion .group_desc dt .lst_title li a.btn_file{display: inline-block; position: relative; margin: 0 2px; width: 19px; height: 22px; vertical-align: middle; background: url(/static/img_admin/ico_file.png) no-repeat 0 0;}
.table_accordion .group_desc dt .lst_title li:nth-child(1){width: 11.3%;}
.table_accordion .group_desc dt .lst_title li:nth-child(2){text-align: left; width: 66.9%;}
.table_accordion .group_desc dt .lst_title li:nth-child(3){width: 11.3%;}
.table_accordion .group_desc dt .lst_title li:nth-child(4){width: 10.5%;}


.table_accordion .group_desc .blank_area .txt_blank{padding: 160px 0 75px; width: 100%; text-align: center; font-size: 18px; color: #333; background: url(/static/img_admin/ico_warning_table.png) no-repeat 50% 80px;}

.table_accordion .group_desc dt.active{box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);z-index:1;}
.table_accordion .group_desc dt.active .lst_title li.num_area{font-size: 18px; font-weight: 700; color: #555;}
.table_accordion .group_desc dt.active .lst_title li.title_area{font-size: 18px; font-weight: 700; color: #000;}
.table_accordion .group_desc dd {display: none; position: relative; padding: 0 90px 0 120px; text-align: left; background-color: #f2f2f2;}
.table_accordion .group_desc dd .txt_area{position: relative; padding: 30px 0; line-height: 24px; font-size: 15px; color: #555;}
.table_accordion .group_desc dd .file_area{padding: 20px 0; border-top: 1px dashed #ccc;}
.table_accordion .group_desc dd .file_area span{display: inline-block; margin-right: 12px; padding-left: 15px; width: 84px; height: 29px; line-height: 29px; font-size: 14px; color: #333; background: url(/static/img_admin/bg_download.png) no-repeat 0 0;}
.table_accordion .group_desc dd .file_area a{display: inline-block; margin-left: 10px; padding-left: 29px; height: 29px; line-height: 29px; font-size: 14px; color: #3348ae; text-decoration: underline; background: url(/static/img_admin/ico_file.png) no-repeat 0 50%;}

.table_accordion.faq .group_desc .ico_faq{display: inline-block; margin-right: 10px; width: 30px; height: 30px; line-height: 30px; text-align: center; border-radius: 50%; font-size: 15px; font-weight: 400; color: #333; background-color: #f2f2f2;}
.table_accordion.faq .group_desc dt.active .ico_faq{font-size: 18px; font-weight: 400; color: #fff; background-color: #3348ae;}
.table_accordion.faq .group_desc dd .ico_faq{position: absolute; left: 0; top: 30px; font-size: 18px; font-weight: 400; color: #fff; background-color: #484e63;}
.table_accordion.faq .group_desc dd .txt_area{padding-left: 38px;}
.table_accordion.faq .group_title li:nth-child(1){width: 11.3%;}
.table_accordion.faq .group_title li:nth-child(2){width: 77.4%;}
.table_accordion.faq .group_title li:nth-child(3){width: 11.3%;}
.table_accordion.faq .group_desc dt .lst_title li:nth-child(1){width: 11.3%;}
.table_accordion.faq .group_desc dt .lst_title li:nth-child(2){text-align: left; width: 77.4%;}
.table_accordion.faq .group_desc dt .lst_title li:nth-child(3){width: 11.3%;}


/*=============================================
	상태
=============================================*/
/* .state_room{display: inline-block; min-width: 56px; height: 24px; padding: 0 5px; line-height: 24px; border: 1px solid #05ad8e; border-radius: 12px; font-size: 14px; color: #05ad8e; text-align: center;} */
.state_type{display: inline-block; min-width: 60px; height: 24px; line-height: 24px; background-color: transparent; border-width: 1px; border-style: solid; border-radius: 12px; font-size: 14px; text-align: center;}
.state_type.type1{border-color: #fbcfce; background-color: #fef8f8; color: #f1615d;}
.state_type.type2{border-color: #f5e3c0; background-color: #fdfbf6; color: #dda12e;}
.state_type.type3{border-color: #b8e6e4; background-color: #f4fbfb; color: #15aca7;}
.state_type.type4{border-color: #cddaf4; background-color: #f7fafd; color: #5985dc;}
.state_type.type5{border-color: #e0e0e0; background-color: #fafafa; color: #666;}

.user_name{display: inline-block; min-width: 60px; height: 26px; line-height: 26px; background-color:#f7fafd; border: 1px solid #cddaf4; border-radius: 12px; font-size: 14px; font-weight: 400; color: #5985dc; text-align: center;}  
.user_name.gray{background-color: #fafafa; border: 1px solid #e0e0e0; color: #666}
.user_name.green{background-color: #f4fbfb; border: 1px solid #b8e6e4; color: #15aca7;}
.user_info{padding: 40px 0 20px; font-size: 16px; font-weight: 700; color: #222;}
.user_info .user_name{margin-right: 8px;}

#contents .emp_red::after{content: ''; display: inline-block; width: 4px; height: 4px; margin-left: 5px; background-color: #ef522e; border-radius: 50%; vertical-align: top;}
.pop_wrap .emp_red{display: block;position: relative; padding-left: 14px; color: #ef522e;font-weight: 400;}
.pop_wrap .emp_red::before{content:'';display: block; position: absolute; left: 0; top: 8px; width: 4px; height: 4px; background-color: #ef522e; -webkit-border-radius: 50; -moz-border-radius: 50%; border-radius: 50%;}


/*=============================================
	유지운영내역
=============================================*/
#pop_companion div.contents_wrap {overflow-y: auto;overflow-x: hidden;max-height: 600px;padding: 0px 40px 0;font-size: 14px;line-height: 24px;color: #333;vertical-align: middle;}
#pop_companion dd.on {display: block;padding: 20px 20px;line-height: 28px;background-color: #fafafa;font-size: 16px;color: #999}
#pop_companion dd.blank_area.type_white {background-color: #fff;padding: 50px 0;height: auto;text-align: center; border-bottom: 1px solid #ebebeb;}
#pop_companion dd.blank_area.type_white p.txt_blank {padding: 76px 0 0;font-size: 18px;color: #333;background: url(/static/img_admin/ico_warning_table.png) no-repeat 50% 0}
/*=============================================
	동별 유지운영내역
=============================================*/
.group_history_dong{position: relative;}
.group_history_dong dt{height: 48px;line-height: 46px;text-align: center;border-top: 1px solid #959595;border-bottom: 1px solid #ebebeb;font-size: 14px;font-weight: 500;color: #333;background: #fafafa;}
.group_history_dong dd{overflow-x: auto; overflow-y: hidden;}
.group_history_dong dd.blank_area.type_white {background-color: #fff;padding: 50px 0;height: auto;text-align: center; border-bottom: 1px solid #ebebeb;}
.group_history_dong dd.blank_area.type_white p.txt_blank {padding: 76px 0 0;font-size: 18px;color: #333;background: url(/static/img_admin/ico_warning_table.png) no-repeat 50% 0}
.group_history_dong .lst_dong{display: inline-flex; flex-flow: row nowrap;width: 100%;}
.group_history_dong .lst_dong li{flex: 0 0 8.3333%;position: relative;text-align: center;font-size: 14px;font-weight: 400;color: #666;word-wrap: break-word;}
.group_history_dong .lst_dong li a{display: block;height: 48px;line-height: 48px; border: 1px solid #ebebeb; margin-top: -1px; margin-left: -1px;}
.group_history_dong .lst_dong li:first-child a{margin-left: 0;}
.group_history_dong .lst_dong li a.on{background-color: #b0e3d9;}
.group_history_dong .lst_dong li a.active{background-color: #71ade6;color: #fff;}

/*=============================================
	숙소정보 상세
=============================================*/
.lst_roomtype{display: flex; flex-flow: row nowrap; background-color: #f7f8f9;}
.lst_roomtype li{flex: 1; padding: 30px 0 30px 2%; border-left: 1px solid #eaebec;}
.lst_roomtype li:first-child{border-left: 0;}
.lst_roomtype li strong{display: block; font-size: 14px; color: #333; font-weight: 400;}
.lst_roomtype li span{display: block; padding-top: 15px; font-size: 16px; color: #222; font-weight: 500;}

/*=============================================
	추가입력
=============================================*/
.lst_write_add{overflow-y: auto; max-height: 300px;}
.lst_write_add ul{}
.lst_write_add ul li{position: relative; height: 50px;padding: 4px 20px;margin-bottom: 10px;border: 1px solid #d1d1d1;border-radius: 4px;}
.lst_write_add ul li::after{content: '';display: block;clear: both;}
.lst_write_add ul li .input_check{float: left; margin-top: 10px;}
.lst_write_add ul li .first{float: left; width: 40%;}
.lst_write_add ul li .second{float: left; width: 40%;}
.lst_write_add ul li .function{position: absolute; right: 7px;top: 50%; transform: translateY(-50%);}
.lst_write_add ul li div label{display: inline-block;width: 100px;padding-left: 40px;border-right: 1px solid #ebebeb;vertical-align: middle;}
.lst_write_add ul li div .form_control{padding: 0 20px;width: 180px;border: 0 none;}

/*=============================================
	버튼 가이드
=============================================*/
/* 공통 버튼 */

.btn {min-width: 100px;height: 40px;line-height: 38px;padding: 0 20px;border-radius: 4px;border: 1px solid #e6e6e6;background: #f7f7f7;color: #b2b2b2;font-size: 14px;cursor: pointer;vertical-align: middle;}
.btn + .btn{margin-left: 10px;}
.btn_xs + .btn_xs{margin-left: 10px;}
.btn_sm + .btn_sm{margin-left: 10px;}
.btn_lg + .btn_lg{margin-left: 10px;}
.btn_md + .btn_md{margin-left: 10px;}
.btn_xs{display:inline-block;min-width:60px;height:32px;line-height:30px;padding:0 15px;border:0;border-radius:4px;font-size:14px;font-weight:400;color:#333;text-align:center;vertical-align:middle;cursor:pointer;transition: all 0.2s ease-in-out;}
.btn_sm{display:inline-block;min-width:80px;height:36px;line-height:34px;padding:0 20px;border:0;border-radius:4px;font-size:14px;font-weight:400;color:#333;text-align:center;vertical-align:middle;cursor:pointer;transition: all 0.2s ease-in-out;}
.btn_md{display:inline-block;min-width:80px;height:40px;line-height:38px;padding:0 20px;border:0;border-radius:4px;font-size:16px;font-weight:400;color:#333;text-align:center;vertical-align:middle;cursor:pointer;transition: all 0.2s ease-in-out;}
.btn_lg{display:inline-block;min-width:200px;height:50px;line-height:50px;padding:0 20px;border:0;border-radius:4px;font-size:16px;font-weight:400;color:#333;text-align:center;vertical-align:middle;cursor:pointer;transition: all 0.2s ease-in-out;}
.btn_xlg{display: inline-block; min-width: 200px; height: 56px; line-height: 54px; padding: 0 20px;border-radius: 4px;border: 1px solid #05ad8e;background: #05ad8e;color: #fff;font-size: 16px; font-weight: 400; transition: background-color 0.5s, border-color 0.5s;}

.btn_link{border: 0;border-radius: 0;min-width: 0;font-size: 14px;color: #333;background-color: transparent;}
.btn_sm.btn_link{line-height: 32px;}

.btn_sm.btn_outline{line-height: 34px;}
.btn_md.btn_outline{line-height: 38px;}
.btn_lg.btn_outline{line-height: 48px;}

.btn_sm .ico_up{display: inline-block; margin-left: 10px; width: 12px; height: 14px; vertical-align: middle; background: url("/static/img_admin/ico_arrow_up.png") no-repeat 0 0;}
.btn_sm .ico_down{display: inline-block; margin-left: 10px; width: 12px; height: 14px; vertical-align: middle; background: url("/static/img_admin/ico_arrow_down.png") no-repeat 0 0;}
.btn_xs .ico_del{display: inline-block; margin-right: 10px; width: 10px; height: 10px; vertical-align: middle; background: url("/static/img_admin/btn_delline.png") no-repeat 0 0;}
.btn_xs .ico_add{display: inline-block; margin-right: 10px; width: 10px; height: 10px; vertical-align: middle; background: url("/static/img_admin/btn_addline.png") no-repeat 0 0;}

.btn_sm.btn_autoheight{padding: 5px 15px !important; height: auto; line-height: 20px;}

.btn_xs:hover, .btn_sm:hover, .btn_md:hover, .btn_lg:hover{box-shadow: 0 2px 3px rgba(0,0,0,0.16);}

/* 컬러 버튼 */
.btn_white{border:1px solid #ccc;background:#fff;line-height:43px;}
.btn_white:hover{background:#f2f2f2;}

.btn_blue{background:#2428a6;color:#fff;}
.btn_lightblue{background:#3c78fe;color:#fff;}
.btn_darkblue{background:#2b3053;color:#fff;border-color:#2b3053;}
.btn_green{background:#05ad8e;color:#fff;border-color:#05ad8e;}

.btn_gray{border: 1px solid #666; background-color: #666; color: #fff; font-weight: 400;}
.btn_darkgray{background-color:#757575;color: #fff;border: 1px solid #ccc;}

.btn_outline{border: 1px solid #aaa; background-color: #fff; color:#333;}
.btn_outline:hover{background:transparent;}
.btn_outline.btn_blue{border:1px solid #3348ae;background:#fff;color:#3348ae;}
.btn_outline.btn_gray{border:1px solid #aaa;background: transparent;color:#333;}
.btn_outline.btn_gray2{border:1px solid #ccc;background: #fff;color:#333;}

/* 디자인 버튼 */
.btn_sm.btn_minus{background:#e4e4e4;border: 1px solid #ccc;border-radius:0;color:#333;line-height:34px;}
.btn_sm.btn_minus::before{content:'-';font-size:20px;margin-right:5px;vertical-align:top;}
.btn_sm.btn_accept{padding: 0; height: 36px; line-height: 36px; width: auto; text-align: center; background-color: #3348AE; color: #fff;}
.btn_sm.btn_accept span{padding-left: 24px; background: url(/static/img_admin/ico_accept.png) no-repeat 0 50%;}
.btn_sm.btn_reject{padding: 0; height: 36px; line-height: 36px; width: auto; text-align: center; background-color: #757575; color: #fff;}
.btn_sm.btn_reject span{padding-left: 24px; background: url(/static/img_admin/ico_reject.png) no-repeat 0 50%;}
.btn_sm.btn_reset{border: 0;padding-left: 40px;padding-right: 0;min-width: 0;text-align: left;background: url(/static/img_admin/ico_reset.png)no-repeat 15px 50%/16px;}
.btn_setting{display:inline-block; width:48px; height: 48px; border-radius: 4px; background: #568af5 url("/static/img_admin/ico_calendar_white.png") no-repeat 50%; border: 0; cursor: pointer;}
.btn_add{display: inline-block; line-height:34px; border: 0;background-color: transparent;}
.btn_add::before{content:'+';display: inline-block;width: 20px;height:20px;line-height:20px; margin-right:5px;border-radius: 50%; background-color: #2b3053; color:#fff;font-size:20px;text-align: center;vertical-align:middle;}
.btn_link{margin-right: 10px; color: #666; display: block;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.btn_radius{border-radius: 18px;}


/* popup_btn(100% *50, 50% * 50) */
.btn_pop_default{display:inline-block;width:100%;height:50px;border:0;font-size:16px;font-weight:400;line-height:50px;text-align:center;vertical-align:middle;cursor:pointer;transition:all 0.2s ease-in-out;}
.btn_pop_default.w50{width:50%;}
.btn_pop_default.btn_white{background:#fff;border-top:1px solid #ccc;line-height:48px;}
.btn_pop_default.btn_white:hover{background:#f2f2f2;}
.btn_pop_default.btn_blue{background:#3348ae;color:#fff;}
.btn_pop_default.btn_blue:hover{background:#324084;}

.submit{margin-top: 30px;}
.submit::after{content: ''; display: block; clear: both;}
.submit button.btn_lg{min-width: 160px; height: 50px; line-height: 48px;}

/*=============================================
	Alert 팝업
=============================================*/
/* 레이어팝업 기본 css */
.pop_dim_wrap { /* necessary css*/ display: none;position: fixed;top: 0;right: 0;left: 0;bottom: 0;width: 100%;height: 100%;overflow: auto;}
.pop_wrap { /* control css */ width:730px;background-color: #fff;box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

/* ALERT POPUP */
.pop_modal.pop_alert{background-color: transparent;}
.pop_modal.pop_alert .pop_wrap{width:400px; padding: 50px 0 0; transform: translate(-50%, -50%); border-radius: 0; border: 1px solid #4e4d4d;}
.pop_modal.pop_alert .pop_wrap .pop_btn_close{position:absolute;top:20px;right:20px;display:inline-block;width:19px;height:19px;border:0;background:url(/static/img_admin/ico_close_allmenu.png) no-repeat 50%;font-size:0;text-indent:-9999px;cursor:pointer;}
.pop_modal.pop_alert .pop_wrap .message_table{display:table;width:100%;height:80px;padding:0 64px;}
.pop_modal.pop_alert .pop_wrap .messageWrap{display:table-cell;font-size:14px;line-height:34px;color:#666;text-align:center;vertical-align:middle;}
.pop_modal.pop_alert .pop_wrap .form_write{margin: 0; padding: 0 64px; border: 0;}
.pop_modal.pop_alert .pop_wrap .button_wrap{padding: 30px 0; font-size:0;text-align:center;}
.pop_modal.pop_alert .pop_wrap .button_wrap button{width: 130px; height: 44px; line-height: 44px; border-radius: 4px;}
.pop_modal.pop_alert .pop_wrap .button_wrap .btn_white{width: 130px; height: 44px; line-height: 42px; border-radius: 4px; border: 1px solid #aaaaaa;}
.pop_modal.pop_alert .pop_wrap .button_wrap button.w50:first-child{border-radius: 0 0 0 8px;}
.pop_modal.pop_alert .pop_wrap .button_wrap button.w50:last-child{margin: 0; border-radius: 0 0 8px 0;}

/* type2 */
.pop_alert_type2 .pop_wrap{width:380px;}
.pop_alert_type2 .pop_wrap .pop_btn_close{position:absolute;top:15px;right:20px;display:inline-block;width:19px;height:19px;border:0;background:url(/static/img_admin/ico_close_allmenu.png) no-repeat 50%;font-size:0;text-indent:-9999px;cursor:pointer;}
.pop_alert_type2 .pop_wrap .title_wrap{height:50px;padding:13px 14px;border-bottom:1px solid #ddd;background:#fff;font-size:18px;line-height:24px;color:#000;}
.pop_alert_type2 .pop_wrap .contents_wrap{position:relative;padding:30px 20px 60px;font-size:16px;line-height:26px;text-align:center;color:#333;vertical-align:middle;}
.pop_alert_type2 .pop_wrap .button_wrap{font-size:0;}
.pop_alert_type2.pop_success .pop_wrap .contents_wrap{padding:30px 20px;}
.pop_alert_type2.pop_success .pop_wrap .pop_tit{font-size:20px;font-weight:700;line-height:30px;}
.pop_alert_type2.pop_success .pop_wrap .pop_desc{font-size:14px;line-height:20px;}
.pop_alert_type2.pop_success .pop_wrap td, .pop_alert_type2.pop_success .pop_wrap th{height:40px;font-size:14px;}
.pop_alert_type2.pop_success .pop_wrap td{text-align:left;padding:0 20px;}

/* MODAL POPUP */
.pop_modal{background: rgba(0, 0, 0, 0.5); z-index: 998; display: block;}
.pop_modal .pop_wrap{position: absolute; top: 50%; left: 50%; width:460px;padding: 0 0 40px; border-radius: 4px;}
.pop_modal .pop_wrap .pop_btn_close{position:absolute;top:33px;right:40px;display:inline-block;width:20px;height:20px;border:0;background:url(/static/img_admin/ico_popclose_block.png) no-repeat 50%;font-size:0;text-indent:-9999px;cursor:pointer;}
.pop_modal .pop_wrap .title_wrap{height:70px;padding:30px 0 20px; margin: 0 40px;background:transparent;font-size:20px;line-height:24px;color:#333; font-weight: 700; border-radius: 4px 4px 0 0; border-bottom: 1px solid #959595;}
.pop_modal .pop_wrap .title_wrap h3 {height: 30px;line-height: 30px;font-family: HDHarmony; font-size: 22px;font-weight: 400;color: #333;}
.pop_modal .pop_wrap .contents_wrap{overflow-y: auto; overflow-x: hidden; max-height: 600px; padding:20px 40px 0;font-size:14px;line-height:24px;color:#333;vertical-align:middle;}
.pop_modal .pop_wrap .button_wrap{padding: 20px 0px 0; font-size:0;text-align:center;}
.pop_modal .pop_wrap .button_wrap button + button{margin-left:10px;}
.pop_modal .pop_wrap .button_wrap .btn_md{min-width: 130px; height: 44px; line-height: 44px;}
.pop_modal .pop_wrap .order_setting li{position: relative; height: 50px; line-height: 48px; padding: 0 54px 0 20px; margin-bottom: 12px; border-radius: 4px; border: 1px solid #d1d1d1;}
.pop_modal .pop_wrap .order_setting li .btn_move{display: inline-block; position: absolute; right:0;top:0; width: 54px; height: 100%; background: url("/static/img_admin/btn_move.png") no-repeat 50%; border-left: 1px solid #d1d1d1; vertical-align: middle;}
.pop_modal .pop_wrap .order_setting li .input_check > .label_text{padding-left: 40px;}
.pop_modal .pop_wrap .btn_control{padding: 0 40px; margin: 20px 0;}
.pop_modal .pop_wrap .btn_control li{display: inline-block; margin-left: -1px;}
.pop_modal .pop_wrap .btn_control li a{display: block; width: 90px; height: 36px; line-height: 36px; border: 1px solid #aaa; color: #333; font-size: 14px; text-align: center;}
.pop_modal .pop_wrap .btn_control li:first-child a{border-radius: 4px 0 0 4px;}
.pop_modal .pop_wrap .btn_control li:last-child a{border-radius: 0 4px 4px 0;}
.pop_modal .pop_wrap .btn_control .btn_outline{border-color: #aaa; color: #333;}
.pop_modal .pop_wrap .btn_lg.btn_outline{line-height: 48px;}

.pop_modal .pop_wrap .title_contents{position: relative;margin-top: 20px;padding-bottom: 10px;border-bottom: 1px solid #666666;}
.pop_modal .pop_wrap .title_contents .right_area{position: absolute;right: 0;top: 0;}
.pop_modal .pop_wrap .title_contents .right_area .txt_sm{display: block; margin-top: 10px;}
.pop_modal .pop_wrap .title_contents .right_area select.form_control{width: 160px;}
.pop_modal .pop_wrap .title_contents .right_area .form_control+.form_control{margin-left: 10px;}
.pop_modal .pop_wrap .title_contents.title_lg{min-height: 72px;}
.pop_modal .pop_wrap .title_contents.title_lg .right_area select.form_control{width: auto; min-width: 114px; vertical-align: top;}

#pop_assign_room .pop_wrap .partition{position: relative; width: 100%; padding-right: 189px; margin-top: 10px;}
#pop_assign_room .pop_wrap .partition::after{content: ''; display: block; clear: both;}
#pop_assign_room .pop_wrap .partition .left_area{width: 943px;}
#pop_assign_room .pop_wrap .partition .right_area{position: absolute; right: 0; top: 0; width: 239px; height: 100%; min-height: 300px; overflow-y: auto; margin-left: 10px; padding: 15px 20px; background-color: #fafafa; border: 1px solid #d1d1d1;}
#pop_assign_room .pop_wrap .partition .right_area h4{line-height: 24px; padding: 0 0 5px; margin:0 0 5px; border-bottom: 1px solid #d1d1d1; font-size: 16px; font-weight: 400; color: #222;}
#pop_assign_room .pop_wrap .partition .right_area .lst_case ul li{width: 100%; display: table; table-layout: fixed;}
#pop_assign_room .pop_wrap .partition .right_area .lst_case ul li span{display: table-cell; padding-left: 5px; font-size: 13px; color: #666; word-break: break-word;}
#pop_assign_room .pop_wrap .partition .right_area .lst_case ul li span.num{width: 22px; padding-left: 0; font-weight: 700; color: #333;}

.pop_modal .pop_wrap .title_contents:first-child{margin-top: 0;}
.pop_modal .pop_wrap .title_contents h3{line-height: 36px;font-size: 22px;font-family: HDHarmony; font-weight: 300;line-height: 36px;}
.pop_modal .pop_wrap .title_contents h4{line-height: 36px;font-size: 14px;font-weight: 400;}

.pop_modal .pop_wrap .button_wrap .btn {min-width: 130px;width: 130px;height: 44px;line-height: 42px;font-size: 16px;font-weight: 400;}
.pop_modal.pop_sm .pop_wrap{width:443px; transform: translate(-50%, -50%);}
.pop_modal.pop_md .pop_wrap{width:600px; transform: translate(-50%, -50%);}
.pop_modal.pop_lg .pop_wrap{width:840px; transform: translate(-50%, -50%);}
.pop_modal.pop_xlg .pop_wrap{width:1100px;height:auto; transform: translate(-50%, -50%);}
.pop_modal.pop_xxlg .pop_wrap{width:1180px;height:auto; transform: translate(-50%, -50%);}

/*=============================================
	탭
=============================================*/
/* 탭 기본 css */
.tab_contents {display: none;}
.tab_contents.on {display: block;}

.group_tab{position: relative; min-height: 40px;}
.group_tab::after{content: ''; display: block; clear: both;}
.group_tab .tabs{float: left;}
.group_tab .right_area{float: right;}
.group_tab .form_control{width: 200px;}
.group_tab .form_control.num{width: 100px;}
.group_tab .form_control + .form_control{margin-left: 10px;}
.group_tab .right_area .form_control {float: left;width: 140px;margin-left: 10px;}

/* tab_md */
.tab_md li{float:left;}
.tab_md a{display:inline-block;width:290px;height:50px;border:1px solid #ccc;font-size:18px;line-height:48px;color:#555;text-align:center;margin-left:-1px;}
.tab_md a.on{border:2px solid #3348ae;font-weight:700;color:#3348ae !important;position:relative;z-index:1;}

/* tab_xs */
.tab_xs{}
.tab_xs li{float:left;}
.tab_xs a{display:inline-block; margin-left: -1px; min-width:119px; padding-left:10px;padding-right:10px;height:48px; border: 1px solid #ededed; font-size:16px;line-height:48px;color:#666;text-align:center; background-color: #fafafa;}
.tab_xs li:first-child a{border-top-left-radius: 4px; border-bottom-left-radius: 4px;}
.tab_xs li:last-child a{border-top-right-radius: 4px; border-bottom-right-radius: 4px;}
.tab_xs a.on{font-weight: 700; border: 0; color:#fff !important; background-color: #05ad8e; border-color: #05ad8e;}

.tab_lg::after{content: ''; display: block; clear: both;}
.tab_lg li{float:left;}
.tab_lg li.w50{width: 50%;}
.tab_lg li.w50 a{width: 100%;}
.tab_lg a{display:inline-block; margin-left: -1px; min-width:140px; padding-left:10px;padding-right:10px;height:40px; border: 1px solid #ededed; font-size:16px;line-height:40px;color:#666;text-align:center; background-color: #fafafa;}
.tab_lg li:first-child a{border-top-left-radius: 4px; border-bottom-left-radius: 4px;}
.tab_lg li:last-child a{border-top-right-radius: 4px; border-bottom-right-radius: 4px;}
.tab_lg a.on{border: 0; color:#fff !important; background-color: #05ad8e; border-color: #05ad8e; font-weight: 700;}

/* stock_serachtit */
.stock_serachtit{border-bottom:1px solid #707070;}
.stock_serachtit li{float:left;}
.stock_serachtit li a{display:inline-block;min-width:105px;height:40px;padding:0 20px;margin-bottom:-1px;margin-left:-1px;border:1px solid #ccc;border-bottom-color:#777;background:#f2f2f2;line-height:39px;color:#555;text-align:center;}
.stock_serachtit.tabs li a.on{position:relative;background:#fff;border:1px solid #777;border-bottom-color:#fff;color:#000;z-index:1;}

/* orderdetail_tabtit */
.orderdetail_tabtit{border-bottom:1px solid #d6d6d6;font-size:0;}
.orderdetail_tabtit li{display:inline-block;text-align:center;}
.orderdetail_tabtit li a{position:relative;display:inline-block;height:50px;margin-bottom:-1px;padding:12px 22px;background:#f5f5f5;border:1px solid #d6d6d6;border-left:0;font-size:16px;color:#555;line-height:25px;}
.orderdetail_tabtit li a.on{position:relative;background:#fff;border-top:2px solid #000;border-bottom:0;color:#000;font-weight:700;}
.orderdetail_tabtit li a.on::after{content:'';display:block;position:absolute;bottom:-1px;left:0;width:100%;height:1px;background:#fff;}
.orderdetail_tabtit li:first-child a{border-left:1px solid #d6d6d6;}

.orderdetail_tabtit.tab_5ea.w100 li{width: 20%;}
.orderdetail_tabtit.tab_5ea.w100 li a{width: 100%;}

/*  20.11.13 추가 */
.table_normal_wirte{border-top: 1px solid #333;}
.table_normal_wirte table{width: 100%; border-collapse: collapse; border-spacing: 0; table-layout: fixed;}
.table_normal_wirte table caption{position: absolute;font-size: 0;left: -99999px;}
.table_normal_wirte table th{height: 55px;text-align: center; border-right: 1px solid #d7dbde; border-bottom: 1px solid #d7dbde;font-size: 14px;font-weight: 500;color: #333;background: #fafafa;}
.table_normal_wirte table td{height: 55px;text-align: center; border-bottom: 1px solid #e1e3eb;padding: 5px 10px;font-size: 14px;font-weight: 400;color: #666;}
.table_normal_wirte table tr th:last-child,
.table_normal_wirte table tr td:last-child{border-right: 0 none;}
.table_normal_wirte table td .form_control{min-width: unset;width: 100%; border: 0 none;background-color: #fff;}
.table_normal_wirte table td .form_control.datepicker{max-width: unset;width: 100%; border: 0 none;background-color: #fff;}
.table_normal_wirte table td .form_control.w50{width: calc(50% - 76px);}
.table_normal_wirte table td .text_area{width: 100%; height: 317px; padding: 5px 0;}

/* popup */
.pop_modal .table_normal_wirte table th{height: 50px;text-align: center;border-right: 0 none;border-bottom: 1px solid #d7dbde;font-size: 14px;font-weight: 500;color: #333;background: #fafafa;}
.pop_modal .table_normal_wirte table tbody th{border-color: #e1e3eb;}
.pop_modal .table_normal_wirte table tbody td{height: 50px;text-align: center;border-right: 1px solid #e1e3eb;border-bottom: 1px solid #e1e3eb;padding: 5px 10px;font-size: 14px;font-weight: 400;color: #666;}
.pop_modal .table_normal_wirte table td .form_control{border: 1px solid #ddd;background-color: #fff; vertical-align: middle;}
.pop_modal .table_normal_wirte table tr th:last-child,
.pop_modal .table_normal_wirte table tr td:last-child{border-right: 0 none;}

.pop_wrap .search_form select{width: 150px;}
.pop_wrap .search_form input[type="text"]{width: 300px;}
.pop_wrap .lst_basicinfo{width: 100%;border-bottom: 1px solid #ddd;}
.pop_wrap .form_write{padding: 30px 0 0;}
.pop_wrap .lst_basicinfo{margin-top: 50px;}
.pop_wrap .lst_basicinfo dt{height: 60px;line-height: 60px;border-top: 1px solid #333;border-bottom: 1px solid #ddd;font-size: 18px;font-weight: 400;color: #333;}
.pop_wrap .lst_basicinfo dd{padding: 20px 30px;}
.pop_wrap .lst_basicinfo dd ul li::after{content: ''; display: block; clear: both;}
.pop_wrap .lst_basicinfo dd ul li {line-height: 45px;}
.pop_wrap .lst_basicinfo dd ul li strong {float: left;font-size: 16px;color: #999;font-weight: 400;}
.pop_wrap .lst_basicinfo dd ul li span {float: right;font-size: 16px;color: #333;font-weight: 500;}
.pop_wrap .lst_msg {margin-top: 30px;padding: 25px 0;text-align: center;background-color: #f7faff;}
.pop_wrap .lst_msg dt {font-size: 16px;font-weight: 700;color: #333;}
.pop_wrap .lst_msg dd {padding-top: 10px;font-size: 14px;color: #666}

.pop_wrap .detail_list{position: relative; display: flex; padding-bottom: 20px; border-bottom: 1px solid #eee;}
.pop_wrap .detail_list .col{display: flex; flex-direction: column; width: 42%;}
.pop_wrap .detail_list .col.dash{width: 16%; justify-content: center; align-items: center;}
.pop_wrap .detail_list .col.dash button{display: block; width: 44px; height: 44px; border-radius: 50%; border: 0; font-size: 0; background-repeat: no-repeat; background-position: 0 0; cursor: pointer;}
.pop_wrap .detail_list .col.dash button.btn_next{background-image: url(/static/img_admin/detail_list_next.png);}
.pop_wrap .detail_list .col.dash button.btn_prev{background-image: url(/static/img_admin/detail_list_prev.png); margin-top: 6px;}
.pop_wrap .detail_list .col .col_title{font-size: 14px; color: #333; margin-bottom: 12px;}
.pop_wrap .detail_list .col .col_content{height: 380px;overflow: auto;}
.pop_wrap .detail_list .col .col_content li{position: relative; height: 50px; line-height: 48px; margin-top: 5px; border-radius: 4px; border: 1px solid #d1d1d1;}
.pop_wrap .detail_list .col .col_content li::after{content: ''; display: block; clear: both;}
.pop_wrap .detail_list .col .col_content li:first-child{margin-top: 0;}
.pop_wrap .detail_list .col .col_content li.active{border-color: #939393;}
.pop_wrap .detail_list .col .col_content li p{float: left; padding: 0 10px;}
.pop_wrap .detail_list .col .col_content li p:nth-child(1){width: 13%; padding-left: 16px;}
.pop_wrap .detail_list .col .col_content li p:nth-child(2){width: 35%;}
.pop_wrap .detail_list .col .col_content li p:nth-child(3){width: 24%;}
.pop_wrap .detail_list .col .col_content li p:nth-child(4){width: 28%; padding-right: 16px;}

#pop_file .download_area .items_area{margin-top: 5px;}
#pop_deduction .pop_wrap .select_area {position: relative;min-height: 40px;margin: 0 0 10px;}
#pop_deduction .pop_wrap .select_area .right_area {position: absolute;right: 0;top: 0;}
#pop_deduction .pop_wrap .select_area .right_area .form_control {width: 150px;}
#pop_deduction .pop_wrap .select_area .right_area .form_control:nth-child(2) {width: 100px;margin-left: 10px;}
#pop_deduction .pop_wrap .txt_help{padding-right: 280px; color: #999;}
#pop_set_manager .pop_wrap{width: 889px;}
#pop_set_manager .pop_wrap .title_wrap{border-bottom: 1px solid #959595;}
#pop_set_manager .title_contents{margin-bottom: 10px;}
#pop_set_manager .title_contents h3{font-size: 16px; color: #333; font-weight: 400;}
#pop_set_manager .text_entry{padding: 20px 40px 0;}
#pop_set_manager .text_entry h3{font-family: HDHarmony; font-size: 16px; color: #333; font-weight: 400;}
#pop_set_manager .text_entry .form_control{}

/* 이미지 팝업 */
#pop_image .pop_btn_close{line-height: 0; font-size: 0; overflow: hidden; text-indent: -10000px; position: fixed; right: 40px; top: 40px; width: 20px; height: 20px; background: url(/static/img_mobile/ico_close_modal_white.png) no-repeat 0 0/20px; z-index: 100;}
#pop_image .pop_wrap{left: 0; top: 0; transform: translate(0); width: 100%; height: 100%; background-color: transparent;  padding: 0;}
#pop_image .pop_wrap .contents_wrap{height: 100%; max-height: 100%; padding: 0;}
#pop_image .pop_wrap .contents_wrap .slide{position: relative; height: 100%;}
#pop_image .pop_wrap .contents_wrap .slide .img_area{display: flex; width: 100%; height: 100%; overflow-x: auto; align-items: center; justify-content: center; text-align: center; white-space: nowrap;}
#pop_image .pop_wrap .contents_wrap .slide .img_area img{max-height: 100%;}

.lst_file{display: inline-block; vertical-align: middle;}
.lst_file.w100{width: 100%;}
.lst_file li{position: relative; float: left; display: inline-block; min-width: 86px; height: 26px; line-height: 25px; margin-left: 10px; padding: 0 10px; background-color: #f8f8f8; border: 1px solid #d1d1d1; border-radius: 12px;}
.lst_file li:first-child{margin-left: 0;}
.lst_file li p{float: left; font-size: 12px;}
.lst_file li .btn_del{float: right; width: 10px;height: 10px; margin: 7px 0 0 10px; background: url("/static/img_admin/btn_file_close.png") no-repeat 100% 50%; font-size: 0; text-indent: -9999px;}

.form_area{display: inline-block; width: 250px; margin-left: 10px; vertical-align: middle;}
.form_area:first-child{margin-left: 0;}
.form_area input.form_control{width: calc(100% - 96px);}
.form_area input.form_control.date{background-image: url("/static/img_admin/ico_calendar.png"); background-repeat: no-repeat; background-position: calc(100% - 10px) 50%;}
.form_area input.form_control.time{width: 90px;}
.form_area button{height: 36px;}
.form_area .btn_sm.btn_darkblue{height: 40px; margin: 0;}
.form_area.select{width: 180px;}
.form_area.select select.form_control{width: 90px;}

.input_file{display: inline-block; position: relative;}
.input_file label{display: inline-block;vertical-align: middle;cursor: pointer;}
.input_file input[type="file"] { /* 파일 필드 숨기기 */position: absolute;width: 1px;height: 1px;padding: 0;margin: -1px;overflow: hidden;clip:rect(0,0,0,0);border: 0;}
.info_use{display: inline-block;min-width: 60px;height: 26px;line-height: 26px;margin-right: 8px;background-color: #fef8f8;border: 1px solid #fbcfce;border-radius: 12px;color: #f1615d;text-align: center;vertical-align: middle;}

.table_normal_list.response table tr th{background-color: #f7faff;}
.table_normal_list table td .send_setting ul li{margin-top: 10px;}
.table_normal_list table td .send_setting ul li:first-child{margin-top: 0;}
.table_normal_list table td .send_setting label{display: inline-block; min-width: 100px; color: #333;}
.table_normal_list table td .send_setting label.no_checked{padding-left: 28px;}
.table_normal_list table td .send_setting .form_area{position: relative;}
.table_normal_list table td .send_setting .form_area .btn_del{position: absolute; right: -30px; top: 50%; margin-top: -10px; width: 19px;height: 19px; border-radius: 50%; border: 1px solid #d1d1d1; background: #f8f8f8 url(/static/img_admin/btn_file_close.png) no-repeat 50%;font-size: 0;text-indent: -9999px;}
.table_normal_list table td .manager{display: inline-block; margin-top: 10px;}
.table_normal_list table td .memo{position: relative;}
.table_normal_list table td .memo::after{content: ''; display: block; clear: both;}
.table_normal_list table td .memo .btn_sm + .btn_sm{margin-left: 10px;}

/* APC08B05 - 발송 대상 선택 팝업 */
.group_select_location{border-top:1px solid#000}
.group_select_location::after{content:'';display:block;clear:both}
.group_select_location dl{float:left;width:33.3333%;border-left:1px solid#ebebeb;border-bottom:1px solid#ebebeb}
.group_select_location dl:first-child{border-left:0}
.group_select_location dl.w100{width: 100%;}
.group_select_location dl dt{height:55px;line-height:55px;text-align:center;font-size:14px;font-weight:700;color:#222;background-color:#fafafa}
.group_select_location dl dd{overflow-y:auto;max-height:240px;min-height:240px}.group_select_location dl dd ul li{font-size:14px;color:#333}
.group_select_location dl dd ul li a{display:block;padding:10px 30px}
.group_select_location dl dd ul li a.type{display:inline-block;vertical-align:middle;padding-right:8px}
.group_select_location dl dd ul li a.num{display:inline-block;vertical-align:middle}
.group_select_location dl dd ul li a.active{background:#f7faff url("/static/img_admin/ico_check_selected.png")no-repeat 100%50%;background-position-x:calc(100% - 30px);font-weight:700;color:#2b3053}
.group_select_location dl dd ul li a:hover{background-color:#fafafa}


.group_select_type{border-top:1px solid#000}
.group_select_type::after{content:'';display:block;clear:both}
.group_select_type dl{border-left:1px solid#ebebeb;border-bottom:1px solid#ebebeb}
.group_select_type dl:first-child{border-left:0}
.group_select_type dl dt{height:55px;line-height:55px;text-align:center;font-size:14px;font-weight:700;color:#222;background-color:#fafafa}
.group_select_type dl dd{overflow-y:auto;}
.group_select_type dl dd ul li{
    float: left;
    width: 16.66666%; font-size:14px;color:#333; border-left:1px solid#ebebeb;}
.group_select_type dl dd ul li:first-child{border-left:0}
.group_select_type dl dd ul li a{display:block;padding:10px 30px}
.group_select_type dl dd ul li a .type{display:block;vertical-align:middle;padding-right:8px}
.group_select_type dl dd ul li a .num{display:block;vertical-align:middle}
.group_select_type dl dd ul li a.active{background:#f7faff url("/static/img_admin/ico_check_selected.png")no-repeat 100% 50%;background-position-x:calc(100% - 30px);font-weight:700;color:#2b3053}
.group_select_type dl dd ul li a:hover{background-color:#fafafa}

.function_area{padding:20px 0 10px;text-align:right}
.function_area .btn_reset{border:0;padding-left:40px;padding-right:0;text-align:left;background:url("/static/img_admin/ico_reset.png")no-repeat 15px 50%/16px}
.items_selected{overflow-y:auto;padding:30px;height:160px;background-color:#fafafa}
.items_area{display: inline-block; color: #b2b2b2;}
.items_area .disabled{padding: 5px 0;}
.wrap_file + .items_area{display: block; margin-top: 10px;}
.item{display: inline-block;vertical-align: middle;margin-right: 10px;padding: 0 10px;height: 26px;line-height: 24px;font-size: 12px;color: #f1615d;border: 1px solid #f7cccb;border-radius: 13px;background: #faf3f3;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 100%;}
.item.del{padding: 0 30px 0 10px;background: #faf3f3 url(/static/img_admin/ico_delete_item.png) no-repeat 100% 50%/9px;background-position-x: calc(100% - 10px);}
.item.file{padding: 0 30px 0 10px; margin: 0 10px 0 0; background: #fafafa url(/static/img_admin/btn_file_down.png) no-repeat 100% 50%/13px 12px; background-position-x: calc(100% - 10px); border-color: #e0e0e0; color: #666;}
.item.file.del{padding: 0 30px 0 10px; background: #fafafa url(/static/img_admin/btn_file_close.png) no-repeat 100% 50%/9px; background-position-x: calc(100% - 10px);}
.images_area .img{margin-bottom: 20px; position: relative; display: inline-block; overflow: hidden; margin: 0 5px 5px 0; vertical-align: middle; width: 100px; height: 100px; border-radius: 8px; border: 1px solid #f5f5f5;}
.images_area .img img{display: block; width: 100%; height: 100%;}
.images_area .img .btn_del{position: absolute; right:5px; top: 5px; width: 15px; height: 15px; background:url("/static/img_admin/btn_file_close_white.png")no-repeat 50%;}
.images_area .img .item{margin-bottom: 10px;}
.function_area{padding:20px 0 10px;text-align:right}
.function_area .btn_reset{border:0;padding-left:40px;padding-right:0;text-align:left;background:url("/static/img_admin/ico_reset.png")no-repeat 15px 50%/16px}

.upload_area {position:relative;margin-top: 30px;padding: 15px 30px;text-align: center;background-color: #fff;border-top: 1px solid #959595;}
.upload_area .wrap_file {position: relative;display: inline-block;padding-right: 90px;width: 560px;}
.upload_area .wrap_file .btn {position: absolute;right: 0;top: 0px;text-align: center;border-top-left-radius: 0;border-bottom-left-radius: 0;}
.upload_area .wrap_file .upload_name {width: 100%;display: inline-block;height: 40px;line-height:38px;vertical-align: middle;background-color: #fff;-webkit-appearance: none;appearance: none;}
.upload_area > label {display: inline-block;padding-right: 10px;height: 40px;line-height: 40px;vertical-align: middle;font-size: 14px;color: #222;}
.upload_area .btn_file{padding-left: 45px; background-image: url(/static/img_admin/btn_form_down.png); background-repeat: no-repeat; background-position: 20px 50%; border-color: #b1b1b1; background-color: #ebebeb; color: #333;}
.upload_area .right_area{position: absolute; right: 0; top: 50%; transform: translateY(-50%);}

/* 브라우저 최적화 */
.browser_pick{position: relative; width: 100%; height: 100%; min-width: 731px; min-height: 600px; background-color: #f8f8f9; text-align: center;}
.browser_pick .message_wrap{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 731px; height: 466px; margin: auto; padding: 53px 60px 60px; background-color: #fff;}
.browser_pick .message_wrap .logo{position: absolute; top: -68px; left: 0; width: 268px; height: 44px; background: url(/static/img_admin/logo.png) no-repeat 0 0; font-size: 0;}
.browser_pick .message_wrap .title{font-family: HDHarmony; padding-top: 122px; line-height: 44px; font-size: 30px; font-weight: 400; color: #222; background: url(/static/img_admin/browser_pick.png) no-repeat 50% 0;}
.browser_pick .message_wrap .detail{margin: 26px 0 36px; line-height: 30px; font-size: 18px; color: #666;}
.browser_pick .message_wrap .btn_lg{min-width: 160px; font-size: 18px;}


.lst_notice.blank_area {border-top: 1px solid #555;background-color: #fafafa;}
.lst_notice.blank_area .txt_blank {width: 100%;padding: 45px 0;background: 0 none;font-size: 16px;text-align: center;}
/*=============================================
	ag-grid
=============================================*/

.ag-theme-alpine{
   /*margin-top: 30px;*/
   font-family: Roboto, NotoSansKR;
}

.ag-theme-alpine .ag-root-wrapper{
   border: 0;
}

.ag-root-wrapper-body.ag-layout-normal{
   margin-left: -1px;
}

/*//header*/
.ag-theme-alpine .ag-header{
   background-color: #fafafa;
   border-bottom-color: #e1e3eb;
}

.ag-header-group-cell-label, .ag-header-cell-label{
   font-size: 14px;
   font-weight: 500;
   color: #333;
   justify-content: center;
}

.ag-theme-alpine .ag-header-cell-resize::after{
   background-color: transparent;
}
.ag-theme-alpine .ag-header-cell::after, .ag-theme-alpine .ag-header-group-cell::after{
   background-color: transparent;
}
.ag-theme-alpine .ag-checkbox-input-wrapper:focus-within, .ag-theme-alpine .ag-checkbox-input-wrapper:active{
   box-shadow: none;
}
.ag-theme-alpine .ag-header-cell:first-child, .ag-theme-alpine .ag-header-group-cell:first-child{
   border-left: 0 !important;
}
.ag-header-group-cell-label, .ag-header-cell-label{font-weight: 400;}

.ag-header-group-cell{
   border-left: 1px solid #e1e3eb;
}
.ag-header-group-cell:first-child{
   border-left: 0;
}
.ag-header-cell{
   justify-content: center;
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell{
   border-left: 1px solid #e1e3eb;
}

.ag-theme-alpine .ag-header-cell:first-child,.ag-theme-alpine .ag-header-group-cell:first-child{
   border-left: 0;
}

.ag-theme-alpine .ag-header-cell:first-child, .ag-theme-alpine .ag-header-group-cell:first-child{
   border-left: 1px solid #e1e3eb;
}

.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell, .ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group{
   border-left: 1px solid #e1e3eb;
   border-top-color: #e1e3eb;
}
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell:first-child, .ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group:first-child{border-left: 0;}

/*// cell*/
.ag-theme-alpine .ag-ltr .ag-cell{
   display: flex;
   align-items: center;
   justify-content: center;
   border-left: 1px solid #e1e3eb;
}
.ag-theme-alpine .ag-ltr .ag-cell:first-child{
   border-left: 0 !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.grid-cell-center{
   justify-content: center;
}
.ag-theme-alpine .ag-ltr .ag-cell.grid-cell-left{
   justify-content: left;
}
.ag-theme-alpine .ag-ltr .ag-cell.grid-cell-right{
   justify-content: right;
}

.ag-theme-alpine .ag-ltr .ag-cell:first-child{
   border-left: 1px solid #e1e3eb;
}

.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
   line-height: 40px;
   /*white-space: nowrap;*/
   /*word-break: break-all;*/
}
.ag-theme-alpine .ag-cell .point{display: block; text-align: center;}

.ag-theme-alpine .ag-ltr .ag-header-select-all{
   margin-right: 5px;
}
.ag-theme-alpine .ag-ltr .ag-row-drag, .ag-theme-alpine .ag-ltr .ag-selection-checkbox, .ag-theme-alpine .ag-ltr .ag-group-expanded, .ag-theme-alpine .ag-ltr .ag-group-contracted{
   margin-right: 5px;
}
.ag-theme-alpine .ag-ltr .ag-header-select-all + .ag-cell-label-container{
   width: auto;
}

.ag-theme-alpine input, .ag-theme-alpine select{border-color: #d1d1d1; border-radius: 4px; font-size:14px;color:#333;vertical-align: middle;}
.ag-theme-alpine input[class^='ag-']:not([type]), .ag-theme-alpine input[class^='ag-'][type='text'], .ag-theme-alpine input[class^='ag-'][type='number'], .ag-theme-alpine input[class^='ag-'][type='tel'], .ag-theme-alpine input[class^='ag-'][type='date'], .ag-theme-alpine input[class^='ag-'][type='datetime-local'], .ag-theme-alpine textarea[class^='ag-']{
   border-color: #d1d1d1;
}

@media ( max-width: 767px ) {
   #calendarSet_wrap{
       position: fixed;
       left: 0;
       top: 0;
       right: 0;
       bottom: 0;
       width: 100%;
   }
   #calendarSet{
       border-top: 1px solid rgba(0,0,0,0.12);
       max-width: none;
       min-width: auto;
       height: auto;
       z-index: 10;
   }
   .calendarSet_btn_close{
       z-index: 11;
       width: 13px;
       height: 13px;
       position: fixed;
       right: 20px;
       top: 15px;
       font-size: 0;
       border: 0 none;
       background: transparent url(/static/img_admin/ico_close_autocomplete.png) no-repeat 0 0/13px;
   }
   .e-calendar .e-content.e-month td,
   .e-bigger.e-small .e-calendar .e-content.e-month td{position: relative;}
   .e-calendar .e-content span.e-day, 
   .e-bigger.e-small .e-calendar .e-content span.e-day{
       position: absolute;
       left: 50%;
       top: 0;
       transform: translateX(-50%);
   }
   .e-calendar .e-content td.e-today:hover span.e-day, 
   .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, 
   .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day, 
   .e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day, 
   .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, 
   .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
   .e-calendar .e-content td.e-focused-date.e-today span.e-day, 
   .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day{background-color: #b51148 ; color: #fff ;opacity: 1; border: 0 none; box-shadow: none;}

}

.editor *{
   position: initial;
   position: unset;
   font-family: initial;
   font-family: unset;
   /* width: initial;
   width: unset;
   height: initial;
   height: unset; */
   line-height: initial;
   line-height: unset;
   color: initial; 
   color: unset; 
   font-size: initial; 
   font-size: unset; 
   font-weight: initial; 
   font-weight: unset; 
   padding: initial;
   padding: unset;
   margin: initial;
   margin: unset;
   border: 0 none;
   border: initial;
   border: unset;
   background: none;
   background: initial;
   background: unset;
   text-align: initial;
   text-align: unset;
   letter-spacing: initial;
   letter-spacing: unset;
   word-wrap: initial;
   word-wrap: unset;
   border-collapse: initial;
   border-collapse: unset;
   border-spacing: initial;
   border-spacing: unset;
   text-shadow: initial;
   text-shadow: unset;
   border-radius: initial;
   border-radius: unset;
}

#editor *{
   position: initial;
   position: unset;
   font-family: initial;
   font-family: unset;
   /* width: initial;
   width: unset;
   height: initial;
   height: unset; */
   line-height: initial;
   line-height: unset;
   color: initial; 
   color: unset; 
   font-size: initial; 
   font-size: unset; 
   font-weight: initial; 
   font-weight: unset; 
   padding: initial;
   padding: unset;
   margin: initial;
   margin: unset;
   border: 0 none;
   border: initial;
   border: unset;
   background: none;
   background: initial;
   background: unset;
   text-align: initial;
   text-align: unset;
   letter-spacing: initial;
   letter-spacing: unset;
   word-wrap: initial;
   word-wrap: unset;
   border-collapse: initial;
   border-collapse: unset;
   border-spacing: initial;
   border-spacing: unset;
   text-shadow: initial;
   text-shadow: unset;
   border-radius: initial;
   border-radius: unset;
}
