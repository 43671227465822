@charset "utf-8";

#pop_open_roomtype{
    .pop_wrap{
        width: 1300px;
    }

    .row{
        display: flex;
        flex-flow: row nowrap;
        padding-top: 40px;
        &:first-child{
            padding-top: 0;
        }

        .col{
            &.col_list{
                flex: 0 0 40%;
                padding-right: 10px;

                .box_scroll_y{
                    overflow-y: auto;
                    max-height: 532px;
                }

                .lst_dong{
                    padding: 5px 0;
                    li{
                      float: left;
                      margin: 5px;
                    }
                }

                .dong{
                    display: inline-block;
                    width: 75px;
                    height: 22px;
                    line-height: 22px;
                    border-radius: 11px;
                    font-weight: 400;
                    font-size: 12px;
                    text-align: center;
                    color: #6094d0;
                    background-color: #e5effb;
                    .num{
                        font-weight: 700;
                    }
                }
                .ho{
                    display: inline-block;
                    padding-left: 5px;
                    font-size: 14px;
                    color: #666;
                }

                .info_room{
                    position: relative;
                    padding: 10px 0;
                    text-align: center;
                    background-color: #f7faff;

                    .lst_dong{
                        margin-top: 10px;
                        li{
                            display: inline-block;
                            margin-left: 10px;
                            padding-left: 10px;
                            border-left: 1px solid #ebebeb;
                            &:first-child{
                                border-left: 0;
                            }
                        }
                    }

                }
            }
            &.col_room{
                flex: 0 0 60%;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0 10px 0 10px;

                .form_case{
                    position: relative;
                    min-height: 200px;
                    &::after{
                        content: '';
                        display: block;
                        clear: both;
                    }
                    .lst_desc{
                        width: 50%;
                    }
                    .right_area{
                        width: calc(50% - 20px);
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
                        overflow-y: auto;
                        padding: 15px 20px;
                        background-color: #fafafa;
                        border: 1px solid #d1d1d1;
                        h4 {
                            line-height: 24px;
                            padding: 0 0 5px;
                            margin: 0 0 5px;
                            border-bottom: 1px solid #d1d1d1;
                            font-size: 16px;
                            font-weight: 400;
                            color: #222;
                        }
                        .lst_case{
                            ul{
                                li{
                                    width: 100%;
                                    display: table;
                                    table-layout: fixed;
                                    span{
                                        display: table-cell; 
                                        padding-left: 5px; 
                                        font-size: 13px; 
                                        color: #666; 
                                        word-break: break-word;
                                        &.num{
                                            width: 22px; 
                                            padding-left: 0; 
                                            font-weight: 700; 
                                            color: #333;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .lst_desc{
                    padding: 5px 0;
                    border-top: 1px solid #959595;
                    border-bottom: 1px solid #ebebeb;
                    li{
                        line-height: 27px;
                        font-size: 14px;
                        color: #666;
                    }
                }

                .form_select{
                    position: relative;
                    padding: 15px 0;

                    .form_control{
                        float: left;
                        width: 114px;
                        margin-right: 10px;
                    }

                    .lst_info{
                        position: absolute;
                        top: 20px;
                        right: 0;

                        li{
                            float: left;
                            position: relative;
                            margin-left: 20px;
                            padding-left: 40px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #333;

                            &::before{
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 50%;
                                -webkit-transform: translateY(-50%);
                                -moz-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                -o-transform: translateY(-50%);
                                transform: translateY(-50%);
                                width: 28px;
                                height: 18px;
                                //border: 1px solid #7fb0e9;
                                background-color: #5bc6b2;
                            }

                            &:nth-child(2)::before{
                                //border-color: #cad6e4;
                                background: #aaaaaa url(/static/img_admin/bg_disabled_room_sm.png) no-repeat 0 0;
                            }
                        }
                    }
                }

                .box_scroll_y{
                    overflow-y: auto;
                    max-height: 400px;
                }

                .lst_room{
                    display: flex;
                    flex-flow: row;
                    li{
                        display: flex;
                        flex-flow: column;
                        flex: 0 0 68px;
                        position: relative;
                        margin: 0px 0 0 -1px;
                        max-width: 68px;
                        border:1px solid #fff;
                        border-top: 0;

                        &:first-child{
                            margin-left: 0;
                        }

                        .num{
                            margin: 0;
                            text-align: center;
                            height: 25px;
                            line-height: 25px;
                            background-color: #666;
                            font-size: 14px;
                            font-weight: 700;
                            color: #fff;
                            span{
                                padding-left: 5px;
                                font-weight: 400;
                            }
                        }

                        .room{
                            flex: 1 1 58px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 5px 8px;
                            text-align: left;
                            background-color: #b0e3d9;
                            border-top: 1px solid #fff;
                            font-size: 14px;
                            font-weight: 700;
                            color: #fff;
                            &:hover{
                                background-color: #71ade6;
                            }
                            &:nth-of-type(1){
                                border-top: 0;
                            }
                            &.disabled{
                                background: #e9e9e9 url("/static/img_admin/bg_room_disabled.png") no-repeat 0 0;
                                background-size: 100% 100%;
                                cursor: not-allowed;
                            }
                            &.blank{
                                background: #f0f0f0 url("/static/img_admin/ico_disabled.png") no-repeat 0 0;
                            }
                            &.on{
                                position: relative;
                                z-index: 10;
                                //margin: 0 -1px 0 -1px;
                                //border: 3px solid #ffac5b;
                                background-color: #71ade6;
                            }

                            span{
                                display: block;
                                line-height: 20px;
                                font-size: 13px;
                                color: #999;
                                &.name{
                                    font-size: 14px;
                                    color: #333;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}
